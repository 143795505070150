import React, { useContext, useState, useEffect, useRef } from "react";
import ReactPlayer, { getCurrentTime } from "react-player/youtube";
import { UserContext } from "./User";
import Cookies from "js-cookie";
import { useParams, useNavigate, useLocation } from "react-router";
import useScore from "../hooks/useScore";
import useProgress from "../hooks/useProgress";
import useAnalytics from "../hooks/useAnalytics";
import useTexts from "../hooks/useTexts";

import HomeButton from "./HomeButton";
import Footer from "./Footer";
import TaskCard from "./TaskCard";
import ArrowLeft from "./ArrowLeft";
import ConfettiComp from "./ConfettiComp";
import PopUpMessage from "./PopUpMessage";
import { addDoc, updateDoc, doc, collection } from "firebase/firestore";
import db from "../firebase";
import { DataContext } from "./DataContext";

export default function Video() {
	const {DATA, setData} = useContext(DataContext);
	const { user, setUser } = useContext(UserContext);
	const location = useLocation();
	const navigate = useNavigate();

	// Get route params and state
	const { packet_id, url, points, data } = location.state;
	const { activity_i } = useParams();
	const activity_index = activity_i - 1;
	const packet_index = parseInt(packet_id.replace(/packet_/,'')-1);

	// State declarations
	const [played, setPlayed] = useState(0);
	const [taskHidden, setTaskHidden] = useState(false);
	const [duration, setDuration] = useState(0);
	const [scoreIncrement, setScoreIncrement] = useState(points);
	const [package_points, setPackage_points] = useState(0);
	const [userScore, setUserScore] = useState(0);
	const [fire, setFire] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	// Refs
	const isMounted = useRef(true);
	const playerRef = useRef(null);

	// Hooks
	const id = Cookies.get().id;
	const [updateScore] = useScore(scoreIncrement, id);
	const [updateProgress] = useProgress(user.progress, id);
	const [addAnalytics] = useAnalytics('Watched video', id, data, user.project_id);

	// Text templates
	const texts = useTexts('unlocked', [
		{ code: '#username#', value: user.firstName },
		{ code: '#link#', value: 'https://app.lagoon.live/#/?unlock=9090909d9kz' }
	], {
		email: {
			subject: 'Module two of Lagoon awaits you, #username#!',
			html: `... your email template ...`
		},
		sms: {
			text: 'You have now unlocked additional activities in Lagoon...'
		}
	});

	// Cleanup on unmount
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	// Update points effect
	useEffect(() => {
		if (!isMounted.current || !user.progress || !user.project_id) return;

		if (user.progress[user.project_id]) {
			setPackage_points(user.progress[user.project_id]['packets'][packet_index].package_points);
			setUserScore(user.score);
		}
	}, [user.progress, user.project_id]);

	// Update user progress and rewards
	const update = async () => {
		try {
			const user_ref = doc(db, "users", Cookies.get().id);
			const updatedUser = { ...user }; // Create new user object

			// Handle rewards
			if (DATA['PACKETS'][packet_index].activities[activity_index]['reward']) {
				const reward = DATA['PACKETS'][packet_index].activities[activity_index]['reward'];
				if (reward.name !== '') {
					updatedUser.rewards.push({
						name: reward.name,
						price: reward.price,
						image: reward.image,
						redeemed: false,
						instruction: reward?.instruction,
					});
				}
			}

			// Update progress
			updatedUser.setprogress = false;
			updatedUser.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed = true;
			updatedUser.progress[user.project_id]['packets'][packet_index].package_points = 
				parseInt(updatedUser.progress[user.project_id]['packets'][packet_index].package_points) + parseInt(points);

			// Update state if component is still mounted
			if (isMounted.current) {
				setUser(updatedUser);
			}

			await updateDoc(user_ref, {
				rewards: updatedUser.rewards,
			});
		} catch (error) {
			console.error('Error in update:', error);
			throw error;
		}
	};

	// Handle button click
	const handleClick = async () => {
		if (!isMounted.current) return;
		
		try {
			setIsLoading(true);
			setError(null);

			await Promise.all([
				updateScore(),
				update(),
				updateProgress(),
				addAnalytics()
			]);

			if (isMounted.current) {
				setFire(true);
			}
		} catch (error) {
			console.error('Error completing video:', error);
			if (isMounted.current) {
				setError('An error occurred. Please try again.');
			}
		} finally {
			if (isMounted.current) {
				setIsLoading(false);
			}
		}
	};

	// Handle video completion
	const videoFinished = async () => {
		if (!isMounted.current) return;

		try {
			setIsLoading(true);
			await Promise.all([
				addAnalytics(),
				update()
			]);
		} catch (error) {
			console.error('Error in videoFinished:', error);
			if (isMounted.current) {
				setError('An error occurred. Please try again.');
			}
		} finally {
			if (isMounted.current) {
				setIsLoading(false);
			}
		}
	};

	// Handle navigation after completion
	useEffect(() => {
		if (!fire) return;

		const timer = setTimeout(() => {
			if (isMounted.current) {
				navigate("/", { replace: true });
			}
		}, 1000);

		return () => clearTimeout(timer);
	}, [fire]);

	const videoStyle = {
		display: "flex",
		width: "90%",
		margin: "auto",
	};

	const buttonStyle = {
		display: "flex",
		margin: "10px auto",
		textAlign: "center",
		color: "blue",
		border: "1px solid purple",
	};
	const taskDescription = user.firstName + ", " + data.taskDescription;

	return (
		<div className="video">
			<header>
				<div className="top">
					<ArrowLeft />
					Watch Video
				</div>
			</header>
			{error && <div className="error-message">{error}</div>}
			<div className={taskHidden ? "task-hidden" : "yo"}>
				<TaskCard taskDescription={taskDescription} />
			</div>
			<div className="video-and-skip-container">
				<div className="video-wrapper">
					<ReactPlayer
						controls
						config={{
							youtube: {
								playerVars: {
									autoplay: true,
									showinfo: 0,
									modestbranding: 1,
									controls: 1,
								},
							},
							vimeo: {
								playerOptions: {
									autoplay: false,
									background: false,
								},
							},
						}}
						//light="https://firebasestorage.googleapis.com/v0/b/lagoon-118b4.appspot.com/o/video-thumb.png?alt=media&token=dbdc7bf8-8a8d-44c6-8e02-3edfd4ea9542"
						width="100%"
						height="100%"
						onStart={() => {
							setTaskHidden(true);
						}}
						onPause={() => {
							setTaskHidden(false);
						}}
						// onProgress={(time) => {
						// 	setPlayed(time.playedSeconds);
						// 	setScoreIncrement(5 * (played / duration).toFixed());
						// 	// setScoreIncrement(5);
						// }}
						onEnded={videoFinished}
						onDuration={(time) => {
							setDuration(time);
						}}
						// style={videoStyle}
						url={data.url}
					/>
				</div>
				<button 
					className="skip-btn" 
					onClick={handleClick}
					disabled={isLoading}
				>
					{isLoading ? 'Processing...' : 'Add to wallet'}
				</button>
			</div>
			{/* <HomeButton /> */}

			<Footer />
		</div>
	);
}
