import { React, useContext } from "react";

import LockIcon from "../assets/lock.png";
import ModuleOne from "../assets/module-one.png";
import ModuleTwo from "../assets/module-two.png";
import { UserContext } from "./User";

export default function Badge ({packetIndex, activeIndex, badges, packet}) {

    const { user, setUser } = useContext(UserContext);

	const handleClick = (index) => {

		// console.log(packet)
		// debugger;

		//console.log('select module '+index);

		user.progress[user.project_id].activeBadge = index;
		setUser(Object.assign({}, user));

		document.querySelector(".badge.active").classList.remove('active');
		document.querySelectorAll(".badge")[index].classList.add('active');

		document.querySelectorAll(".card-three").forEach((module_activities, i) => {

			//console.log(i+' == '+index);

			if (i != index) {
				module_activities.classList.add("disabled");
			} else {
				if (module_activities.classList.contains("completed"))
					module_activities.classList.remove("completed")

				module_activities.classList.remove("disabled");
			}
		});

	};

	return (

		<div key={packetIndex} className={`badge ${packetIndex == activeIndex ? "active" : ""} ${badges[packetIndex] === "is_awarded" ? "" : "hidden-in-wallet" }`} onClick={() => handleClick(packetIndex)}>

		{!!packet.image ?

			badges[packetIndex] === "is_awarded" ?
				<>
				<img src={packet.image.completed} />
				</>
				:
				badges[packetIndex] === "locked" ?
					<>
					<img src={packet.image.loсked} />
					</>
					:
					<>
					<img src={packet.image.unlocked} />
					</>

			:

			badges[packetIndex] === "is_awarded" ?
				<img src={packetIndex == 0 ? ModuleOne : ModuleTwo}></img>
				:
				badges[packetIndex] === "locked" ?
					<img src={LockIcon}></img>
					:
					<img src={packetIndex == 0 ? ModuleOne : ModuleTwo}></img>

		}
		<p className="badge-name">{packet.name}</p>
	</div>

	)

}
