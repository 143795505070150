import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { DataContext } from "./DataContext";
// import "../styles/PopUpMessage.css";

const PopUpMessage = (props) => {
	const { DATA, setDATA } = useContext(DataContext);
	const navigate = useNavigate();
	const [close, setClose] = useState(false);
	const handleClick = () => {
		setClose(true);

		let url = '/';
		if (DATA && DATA['ID']);
			url = '/'+DATA['ID']+'/';

		navigate(url);
	};
	return props.trigger ? (
		<div className={close ? "hidden popup" : "visible popup"} >
			<div className="popup-inner rounded">
				{props.children}
				<br />
				<button
					className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
					onClick={props.closeModal ? props.closeModal : handleClick}
				>
					Ok
				</button>
			</div>
		</div>
	) : (
		""
	);
};

export default PopUpMessage;
