import React, { useEffect, useState, useContext } from "react";
import db from "../firebase";
import {
	onSnapshot,
	collection,
	query,
	orderBy,
	limit,
} from "firebase/firestore";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import ArrowLeft from "./ArrowLeft";
import Cookies from "js-cookie";
import { UserContext } from "./User";

const FullDashboard = () => {

	const { user, setUser } = useContext(UserContext);

	const [users, setUsers] = useState([]);
	let num = 0;

	useEffect(() => {
		const unsub = onSnapshot(
			query(collection(db, "users"), orderBy("score", "desc")),
			(snapshot) => {

				let users = [];
				let items = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				for (let key in items){
					if (items[key].progress && user.project_id && items[key].progress[user.project_id])
						users.push(items[key]);
				}
				setUsers(users);
			}
		);
		return () => unsub();
	}, [user]);

	return (
		<div className="full-dashboard">
			<header>
				<div className="top">
					<ArrowLeft />
					Leaderboard
				</div>
			</header>
			<svg
				id="dot"
				width="8"
				height="8"
				viewBox="0 0 8 8"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="4" cy="4" r="4" fill="#41295a" />
			</svg>
			<ol className="full-leaderboard">
				{users.map((user) => (

					<li key={user.id}>
						<div
							className={`name-card ${user.id === Cookies.get().id ? "me" : ""}`}
						>
							<div className="main">
								<img className="avatar" src={user.avatarUrl} alt="avatar" />
								<div className="info">
									<p className="name">
										{user.firstName}{" "}
										<span
											className={`${user.lastName === "" ? "hide-last" : ""}`}
										>
											{user.lastName[0]}.
										</span>{" "}
										{"  "}
										{/* <span className="points">{user.companyId}</span> */}
									</p>
									<div className="compid">
										<img
											src="/img/star-icon-or-symbol-success-ranking-element-icon-concept-cartoon-illustration-3d-rendering-3.png"
											className="logo"
										/>
										{user.score} points
									</div>
								</div>
							</div>
						</div>
					</li>

				))}
			</ol>
			<Footer />
		</div>
	);
};

export default FullDashboard;
