import db from "../firebase";
import Cookies from "js-cookie";
import { setDoc, onSnapshot, collection, doc, updateDoc, increment, arrayUnion, Timestamp } from "@firebase/firestore";

export default function useNotifications (projectId, admin) {

	const analyticsRef = doc(db, "analytics", projectId);
	const now = Timestamp.now();

	//==================================
	// add user to project analytics

	const updateAnalitics = async () => {
		const userId = Cookies.get().id;

		//setDoc(cityRef, { capital: true });

		await setDoc(analyticsRef, {
			users: arrayUnion(userId),
		}, { merge: true });
		updateNotifications();
	}

	//==================================
	// add notifications

	const updateNotifications = async () => {

		console.log('updateNotifications');

		// get number of users
		onSnapshot(analyticsRef, (doc) => {
			let project = doc.data();

			if (project.users) {

				let count = project.users.length;

				createNotification(5, project);
				createNotification(10, project);
				createNotification(50, project);
			}

		});
	}

	//==================================
	const createNotification = async (count, project) => {

		console.log('count');
		console.log(count);
		console.log(project);

		if (project.users.length >= count && (!project.notifications || !project.notifications[count])) {

			// add to analytics collection (eliminate duplicate notifications)
			if (!project.notifications)
				project.notifications = {};
			project.notifications[count] = true;
			console.log(project.notifications);
			await setDoc(analyticsRef, {
				notifications: project.notifications,
			}, { merge: true });

			// add to notification collection
			const notificationsRef = doc(db, "notifications", admin);
			await setDoc(notificationsRef, {
				notifications: arrayUnion(
					{
						timestamp: now,
						project: projectId,
						count: count,
					}
				),
			}, { merge: true });


		}
	}
	//==================================

	updateAnalitics();

}
