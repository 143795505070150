import db from "../firebase";
import { useContext } from "react";
import { UserContext } from "../components/User";
import { doc, updateDoc, onSnapshot } from "@firebase/firestore";

export default function useScore(progress, id) {
	const { user, setUser } = useContext(UserContext);

	const updateProgress = async () => {
		//console.log(user);
		const _ref_users = await doc(db, "users", id);
		//console.log(_ref_users);
		updateDoc(_ref_users, {
			progress: progress,
		});
	};

	return [updateProgress];
}
