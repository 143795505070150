import { React, useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import sendMessage from "./sendMessage";
import { UserContext } from "./User";
import twilioConfig from "../twilioConfig";
import { collection, addDoc } from "firebase/firestore";
import db from "../firebase";

const client = require("twilio")(
	twilioConfig.accountSid,
	twilioConfig.authToken
);

export default function Twilio() {
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const { user, setUser } = useContext(UserContext);
	const [number, setNumber] = useState("");
	const [body, setBody] = useState("");

	// const sendMessage = async () => {
	// 	client.messages
	// 		.create({
	// 			from: twilioConfig.phoneNumber,
	// 			to: number,
	// 			body: body,
	// 		})
	// 		.then((res) => {
	// 			// res.send(JSON.stringify({ success: true }));
	// 			console.log(res);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 			// res.send(JSON.stringify({ success: false }));
	// 		});

	// 	// const res = await fetch("/sendM", {
	// 	// 	method: "POST",
	// 	// 	headers: {
	// 	// 		"Content-Type": "application/json",
	// 	// 	},
	// 	// 	body: JSON.stringify({ to: number, body: body }),
	// 	// });

	// 	// const data = await res.json();

	// 	// if (data.success) {
	// 	// 	await setNumber("");
	// 	// 	await setBody("");
	// 	// } else {
	// 	// 	await setNumber("An Error has occurred.");
	// 	// 	await setBody("An Error has occurred.");
	// 	// }
	// 	console.log(number, body);
	// };

	const sendMessage = async (bod, num) => {
		console.log(num, bod);
		const newText = addDoc(collection(db, "sms"), {
			to: num,
			body: bod,
		}).then((docRef) => {
			console.log(docRef);
		});
	};
	return (
		<>
			<form>
				{/* <label htmlFor="firstName">First Name</label> */}
				<input
					type="textarea"
					{...register("message")}
					placeholder="Your message"
				/>
				<input
					type="tel"
					{...register("to", {
						required: true,
						minLength: 6,
						maxLength: 12,
						pattern: {
							value:
								/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
							message: "Invalid number",
						},
					})}
					placeholder="Please enter the number"
				/>
				<p>{errors.to?.message}</p>
				<button
					onClick={handleSubmit(async (data, e) => {
						e.preventDefault();
						setBody(data.message);
						setNumber(data.to);
						await sendMessage(data.message, data.to);
						reset();
					})}
					type="submit"
				>
					Send
				</button>
			</form>
		</>
	);
}
