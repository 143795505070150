import db from "../firebase";
import { addDoc, setDoc, collection, doc, updateDoc, increment, arrayUnion, serverTimestamp } from "@firebase/firestore";

export default function useAnalytics(action, user_id, optional, project_id) {

	const addAnalytics = async () => {

		const _ref_actions = await collection(db, "actions");
		const _optional = !optional ? {} : optional;

// 		console.log('Analytics');
// 		console.log(_optional);
//
// 		console.log({
// 			user_id: user_id,
// 			action: action,
// 			analytic_data: _optional,
// 			project_id: project_id,
// 			timestamp: new Date(),
// 		});

		addDoc(_ref_actions, {
			user_id: user_id,
			action: action,
			analytic_data: _optional,
			project_id: project_id,
			timestamp: new Date(),
		});

		//=======================================
		// update analytics data in project

		const ref = await doc(db, "analytics", project_id);
		await setDoc(ref, {
			last_activity: serverTimestamp(),
			user_activities: increment(1),
		}, { merge: true });

		//=======================================
		// umami

		// umami.trackEvent(action);
		// umami.trackEvent(action);
		// Check if Umami is loaded
		// debugger;
		if (window.umami) {
			if (optional && optional.fileName) {
				window.umami.track(action + ": " + optional.fileName);
			} else if (optional && optional.url) {
				window.umami.track(action + ": " + optional.url);
			} else {
				window.umami.track(action);
			}

		} else {
			console.error('Umami not loaded');
		}
	};
	return [addAnalytics];
}
