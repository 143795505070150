import React from "react";
import ArrowLeft from "./ArrowLeft";
import Footer from "./Footer";
import Profile from "./Profile";
import Cookies from "js-cookie";
import useCookie from "../hooks/useCookie";
import BusinessCard from "./BusinessCard";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./User";
import { DataContext } from "./DataContext";
import { useContext } from "react";

const ProfilePage = () => {
	const cookie = Cookies.get().id;
	const [value, update, remove] = useCookie("id", cookie);

	const navigate = useNavigate();
    const { setUser } = useContext(UserContext);
    const { setDATA } = useContext(DataContext);

    const handleLogout = () => {
        // Store the projectId before clearing cookies
        const projectId = Cookies.get('projectId');

        // Remove all cookies
        Object.keys(Cookies.get()).forEach(cookieName => {
            Cookies.remove(cookieName);
        });

        // Set the projectId cookie back
        if (projectId) {
            Cookies.set('projectId', projectId);
        }

        // Reset user and DATA contexts
        setUser({});
        setDATA({});

        // Navigate to the intro page
        navigate("/signup");

        // Optionally, reload the page to ensure a fresh start
        // Be cautious with this as it might clear other necessary state
        window.location.reload();
    };

	return (
		<>
			<div className="profile-page">
				<header>
					<div className="top">
						<ArrowLeft />
						<p>Wallet</p>
					</div>
				</header>
				<Profile/>
				<div onClick={handleLogout} className="logout-btn">
					Log out
				</div>
				{/* <button onClick={remove}>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M13.9081 15.1908C12.5612 16.339 10.8288 17.0278 8.94613 17.0278C4.68899 17.0278 1.2 13.5058 1.2 9.11392C1.2 4.72206 4.68899 1.2 8.94613 1.2C10.8298 1.2 12.5631 1.88955 13.9102 3.03888H15.6151C13.977 1.1738 11.5961 0 8.94613 0C4.00532 0 0 4.08044 0 9.11392C0 14.1474 4.00532 18.2278 8.94613 18.2278C11.5953 18.2278 13.9754 17.0548 15.6135 15.1908H13.9081Z"
									fill="#B63FFF"
								/>
								<path
									d="M7.45499 9.11401H16.4011"
									stroke="#B63FFF"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M14.0154 6.83545L16.9974 9.11393"
									stroke="#B63FFF"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M16.9976 9.11401L14.0156 11.3925"
									stroke="#B63FFF"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button> */}
				<Footer />
			</div>
		</>
	);
};

export default ProfilePage;
