import react, { useContext, useEffect, useState } from "react";
import { UserContext } from "./User";
import db from "../firebase";
import { updateDoc, doc, onSnapshot, query, where, collection, getDocs } from "firebase/firestore";
import { Link, useNavigate, NavLink } from "react-router-dom";

export default function Projects() {

	console.log('start projects');

	const { user, setUser } = useContext(UserContext);

	const [projects, setProjects] = useState([]);

	useEffect( async () => {

		if (user.progress){

			let ids = [];
			for (let id in user.progress){
				if (id != 'false' && id != 'undefined')
					ids.push(id);
			}
			const projects_ref = collection(db, "projects");
			const q = query(projects_ref, where("__name__", "in", ids));
			const querySnapshot = await getDocs(q);
			let temp = [];
			querySnapshot.forEach((doc) => {
				temp[doc.id] = {id: doc.id, name: doc.data().name};
			});
			setProjects(temp);

		}
	}, [user.progress]);

	useEffect(() => {

		console.log('projects');
		console.log(projects);

	}, [projects, user]);

	return (


		<div className="projects">

			<h2>Projects</h2>

			{
				Reflect.ownKeys(projects).length > 1 ?

					Object.keys(projects).map((key) => {


						return (
								<div key={key}>
									<NavLink to={"/"+key+'/'}>
										{projects[key].name}
									</NavLink>
								</div>
							)
						}

					)

					:

					<>
						No projects found
					</>
			}

		</div>
	);
}
