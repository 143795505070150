import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from "react";
import { DataContext } from "./DataContext";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperCore, { Navigation } from 'swiper/core';
import Badge from "./Badge";
import { UserContext } from "./User";

SwiperCore.use([Navigation]);

const MemoizedBadge = React.memo(Badge);

export default function Badges({badges}) {
	const { user } = useContext(UserContext);
	const { DATA } = useContext(DataContext);
	const [activeIndex, setActiveIndex] = useState(0);
	const [showSlider, setShowSlider] = useState(false);
	const lastValidBadgesRef = useRef(badges);

	useEffect(() => {
		if (badges && badges.length > 0) {
			lastValidBadgesRef.current = badges;
		}
	}, [badges]);

	// Memoize the active index calculation
	const memoizedActiveIndex = useMemo(() => {
		if (user.progress && 
			user.progress[user.project_id] && 
			user.progress[user.project_id].activeBadge > 0 && 
			user.progress[user.project_id].activeBadge <= user.progress[user.project_id].packets.length) {
			return user.progress[user.project_id].activeBadge;
		}
		return 0;
	}, [user.progress, user.project_id]);

	useEffect(() => {
		if (memoizedActiveIndex !== activeIndex) {
			setActiveIndex(memoizedActiveIndex);
		}
	}, [memoizedActiveIndex, activeIndex]);

	useEffect(() => {
		if (DATA['unlock']) {
			setActiveIndex(DATA['unlock']);
		}
	}, [DATA]);

	useEffect(() => {
		if (DATA.PACKETS && DATA.PACKETS.length > 3) {
			setShowSlider(true);
		}
	}, [DATA.PACKETS]);

	// Memoize the badges rendering
	const memoizedBadges = useMemo(() => {
		return DATA.PACKETS.map((packet, packetIndex) => (
			<MemoizedBadge 
				key={packetIndex}
				packetIndex={packetIndex} 
				activeIndex={activeIndex} 
				badges={lastValidBadgesRef.current} 
				packet={packet} 
			/>
		));
	}, [DATA.PACKETS, activeIndex, lastValidBadgesRef.current]);

	return (
		<>

			{showSlider ?

				<div className="slider-badges">

					<Swiper
						spaceBetween={0}
						slidesPerView={3}
						navigation={true}
						modules={[Navigation]}
						>

						{memoizedBadges.map((badge, index) => (
							<SwiperSlide key={index}>{badge}</SwiperSlide>
						))}

					</Swiper>

				</div>

				:

				<div className="row badges">

					{memoizedBadges}

				</div>

			}

		</>
	);
}
