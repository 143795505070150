import react from "react";
import Confetti from "react-dom-confetti";

export default function ConfettiComp({ fire }) {
	const config = {
		angle: 90,
		spread: 360,
		startVelocity: 40,
		elementCount: 70,
		dragFriction: 0.12,
		duration: 2500,
		stagger: 30,
		width: "15px",
		height: "15px",
		perspective: "500px",
		colors: ["#000", "#f00"]
	};
	return <Confetti active={fire} config={config} />;
}
