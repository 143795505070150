import react from "react";
import Confetti from "react-dom-confetti";

export default function PacketConfetti({ fire }) {
    const config = {
        angle: "150",
        spread: "294",
        startVelocity: "50",
        elementCount: "100",
        dragFriction: "0.17",
        duration: "2500",
        stagger: "10",
        width: "100px",
        height: "100px",
        perspective: "50",
        colors: ["var(--activity_background)", "var(--activity_background)"]
      };
	return <Confetti active={fire} config={config} />;
}
