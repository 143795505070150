import react, { useContext, useEffect, useState } from "react";
import Activities from "./Activities";
import { useSearchParams, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Packets from "./Packets";
import Profile from "./Profile";
import { UserContext } from "./User";
import { doc, updateDoc,onSnapshot } from "firebase/firestore";
import db from "../firebase";
import Cookies from "js-cookie";
import ConfettiComp from "./ConfettiComp";
import PopUpMessage from "./PopUpMessage";
import useScore from "../hooks/useScore";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DataContext } from "./DataContext";
import { useParams } from 'react-router-dom';
import { DefaultData, ProcessData } from "./Data";
import Projects from "./Projects";

gsap.registerPlugin(ScrollTrigger);

export default function DashBoardProjects() {

	console.log('dashboard projects');

    const { user, setUser } = useContext(UserContext);

    const { DATA, setDATA } = useContext(DataContext);

	const [popUp, setPopUp] = useState(false);
	const [fire, setFire] = useState(false);
	const [popUpMessage, setPopUpMessage] = useState(null);
	const [searchParams] = useSearchParams();
	let unlockParam = searchParams.get("unlock");
	let confirmParam = searchParams.get("confirm");
	const [updateScore] = useScore(10, Cookies.get().id);
	const [pointsModal, setPointsModal] = useState(false);
	const [giftCardUnlocked, setGiftCardUnlocked] = useState(false);
	const navigate = useNavigate();

		//===============================

    //*** get default data ***

    useEffect(() => {

        if (!DATA['processed']) {

            console.log('default data processing');

            let Data = ProcessData(DATA);
            setDATA(Data);
            //console.log ('set default data');

            //console.log(DATA);

        }

    }, [user]);

	useEffect(() => {

        let Data = DATA;
		Data['allProjects'] = true;
		setDATA(Data);

    }, [DATA]);



	//=======================================

	const closeModal = () => {
		props.closeModal(false);
	};

	const closePage = () => {
		navigate("/");
	};

	function onModalChange(newState) {
		setPopUp(newState);
	}

	const completeProfile = () => {

		if (user.progress && typeof(DATA) !== 'undefined' && user.progress[user.project_id] && user.progress[user.project_id]['packets'] && !DATA['defaultData']){

			if (user.progress[user.project_id].bio) {

				navigate(user.progress[user.project_id].bio.link);
				Cookies.set("didShowProgressNotificationModuleOne", true);

			}
		}
	};

	let tl = gsap.timeline({
		// yes, we can add it to an entire timeline!
		scrollTrigger: {
			trigger: ".profile",
			pin: false, // pin the trigger element while active
			start: "top top", // when the top of the trigger hits the top of the viewport
			end: "+=1000", // end after scrolling 500px beyond the start
			scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
		},
	});

	// tl.call(document.querySelector('.greetings').)
	// tl.to(".greetings", { position: "fixed", top: "0", width: "100%", background: "rgba(0,0,0,0.9)" });

    const updateUserProgress = async (progress) => {
        const id = Cookies.get("id");
        const _ref_users = await doc(db, "users", id);
        await updateDoc(_ref_users, {
            progress: progress,
        });
    };

	useEffect(() => {


		// if (
		// 	Cookies.get().showProgressNotificationModuleOne &&
		// 	Cookies.get().didShowProgressNotificationModuleOne == "false" &&
		// 	Cookies.get().profileComplete != "true"
		// ) {
		// 	setPointsModal(true);
		// 	setPopUpMessage(
		// 		`You are 10 pts away from your first reward. Complete your profile to claim.`
		// 	);
		// 	setPopUp(true);
		// 	setFire(true);
		// }
		// if (!Cookies.get().giftCardEarnedShow && user.score >= 3) {
		// 	setTimeout(() => {
		// 		setGiftCardUnlocked(true);
		// 		setPopUpMessage("You just unlocked your first reward");
		// 		setPopUp(true);
		// 		setFire(true);
		// 		Cookies.set("giftCardEarnedShow", true);
		// 	}, 500)
		// }
		if (user.progress && typeof(DATA) !== 'undefined') {

			if (user.progress && user.progress[user.project_id] && user.progress[user.project_id]['packets'] && !DATA['defaultData']){

				if (confirmParam === Cookies.get().id) {

					if (user.progress[user.project_id].bio) {

						let packet_index = user.progress[user.project_id].bio.packet_index;
						let activity_index = user.progress[user.project_id].bio.activity_index;
						if (!user.progress[user.project_id]['packets'][packet_index]['activities'][activity_index].completed){

							user.progress[user.project_id]['packets'][packet_index]['activities'][activity_index].completed = true;
							updateUserProgress(user.progress);
							updateScore(DATA['PACKETS'][packet_index]['activities'][activity_index].points, Cookies.get().id);
						}

					}

				}

				//==========================

				Object.values(user.progress[user.project_id]['packets']).forEach((packet, packet_index) => {

					//console.log(packet.packet+' === '+unlockParam);
					if (packet.packet === unlockParam) {

						// debugger;

						//console.log('unlock param '+unlockParam);

						user.progress[user.project_id]['packets'][packet_index].packet_activated = true;
						user.progress[user.project_id]['packets'][packet_index].badge.is_unlocked = true;

						if (packet_index > 0)
							user.progress[user.project_id]['packets'][packet_index-1].packet_completed = true;

						updateUserProgress(user.progress);

						setPopUpMessage("You just unlocked Module"+DATA['PACKETS'][packet_index]['name']+"!");
						setPopUp(true);
						setFire(true);
					}
				});
			}
		}
	}, [user, DATA]);


	//console.log('dashboard');

	//console.log(DATA);

	return (


		<div className="dashboard">

			{ DATA && DATA.status == 'draft' ?

				<div className="disabled-project">
					This project is disabled
				</div>
				:
				<>

					<ConfettiComp fire={fire} />
					<PopUpMessage onModalChange={onModalChange} trigger={popUp}>
						{
							<div>
								<h2>Congratulations!</h2>
								<p>{popUpMessage}</p>
								{pointsModal && (
									<button
										style={{ width: "200px", marginBottom: "-35px" }}
										className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
										onClick={completeProfile}
									>
										Complete my profile
									</button>
								)}
								{giftCardUnlocked && (
									<button
										style={{ width: "200px", marginBottom: "-35px" }}
										className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
										onClick={() => navigate("/rewards")}
									>
										Check my Rewards
									</button>
								)}
							</div>
						}
					</PopUpMessage>

					<Profile showAllBadges={true} />

					<Projects />

					<Footer />
				</>
			}

		</div>
	);
}
