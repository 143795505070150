import React, { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "./User";
import Badges from "./Badges";
import BadgesUnlocked from "./BadgesUnlocked";
import db from "../firebase";
import useCookie from "../hooks/useCookie";
import ConfettiComp from "./ConfettiComp";
import { addDoc, orderBy, query, } from "firebase/firestore";
import Cookies from "js-cookie";
import { doc, onSnapshot, updateDoc, collection } from "@firebase/firestore";
import useProgress from "../hooks/useProgress";

import { getDocs, getDoc } from "firebase/firestore";
import { DataContext } from "./DataContext";

import Score from "./Score";
import HeaderGreetings from "./HeaderGreetings";
import PopUpMessage from "./PopUpMessage";
import ReactPlayer, { getCurrentTime } from "react-player/vimeo";
import BusinessCard from "./BusinessCard";
import { NavLink } from "react-router-dom";
import Wallet from "./Wallet";
import ProgressBar from "./ProgressBar";
// import { AchievementTiersComponent } from "@insertcoinab/gwen-react-components";
import ProgressBar2 from "./ProgressBar2";
import AirdropsList from "./AirdropsList";
import InAppNotification from './InAppNotification';

import AppleSvg from "../assets/walletcard.svg";

const Profile = (props) => {

	let cookie = Cookies.get().id;
	const [value, update, remove] = useCookie("id", cookie);
	const [fire, setFire] = useState(false);
	const [users, setUsers] = useState([]);
	const [rating, setRating] = useState(1);
	const [score, setScore] = useState(0);
	const [userAvatar, setUserAvatar] = useState("/img/avatar-1.png");
	//const [badge1, setBadge1] = useState(false);
	//const [badge2, setBadge2] = useState(false);
	const [image, setImage] = useState();
	const [name, setName] = useState("");
	const [emailSent, setEmailSent] = useState(false);
	const [avatar, setAvatar] = useState("/img/avatar-1.png");
	const [voucher, setVoucher] = useState("You have no active vouchers");

	const { user, setUser } = useContext(UserContext);
	const { DATA, setDATA } = useContext(DataContext);

	const [airdrops, setAirdrops] = useState([]);
	const [filteredAirdrops, setFilteredAirdrops] = useState([]);

	const id = Cookies.get().id;
	const [updateProgress] = useProgress(user.progress, id);


	const [showProjects, setShowProjects] = useState(false);

	const [moduleNotification, setModuleNotification] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState(null);
	const [notificationButtonText, setNotificationButtonText] = useState(null);
	const [notificationButtonUrl, setNotificationButtonUrl] = useState(null);

	function onNotificationChange(newState) {
		// alert('ON NOTIFICATION')
		setModuleNotification(newState);
	}

	useEffect(() => {
		if (DATA['allProjects'])
			setShowProjects(true);
		else
			setShowProjects(false);

	}, [DATA, DATA['allProjects']]);

	const [badgeQuiz, setBadgeQuiz] = useState(false);
	const [badgeVideo, setBadgeVideo] = useState(false);
	const [badgeDownload, setBadgeDownload] = useState(false);
	const [companyName, setCompanyName] = useState("");
	const [badgeCompany, setBadgeCompany] = useState(false);
	const [popup, setPopup] = useState(false);
	const [popupAdd, setPopupAdd] = useState("");
	const [popupEmoji, setPopupEmoji] = useState("");
	const [popupHeading, setPopupHeading] = useState("");
	const [popupContent, setPopupContent] = useState("");
	const [popupText, setPopupText] = useState("");
	const [classChange, setClassChange] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const [buttonForDetails, setButtonForDetails] = useState("Show Details");
	//const [percentage1, setPercentage1] = useState(0);
	//const [percentage2, setPercentage2] = useState(0);
	//const [progress1, setProgress1] = useState(0);
	//const [progress2, setProgress2] = useState(0);
	const [totalPoints, setTotalPoints] = useState("");
	const [userPoints, setUserPoints] = useState(0);
	const [badges, setBadges] = useState(false);

	const [percentages, setPercentages] = useState(0);
	const [progress, setProgress] = useState(0);
	const [v, setV] = useState(0);

	const [progressBars, setProgressBars] = useState([]);

	const isMounted = useRef(true);

	// Update user information when user object changes
	useEffect(() => {
		if (!isMounted.current || !user) return;
		
		setScore(user.score || 0);
		setUserAvatar(user.avatarUrl || "/img/avatar-1.png");
		setName(user.firstName || "");
		setImage(user.logoUrl || "");
		setVoucher(user.drinkCode || "You have no active vouchers");
	}, [user]);

	// Clean up on unmount
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	// Update user information when user object changes
	useEffect(() => {
		if (!isMounted.current || !user) return;
		
		setScore(user.score);
		setUserAvatar(user.avatarUrl);
		setName(user.firstName);
		setImage(user.logoUrl);
		setVoucher(user.drinkCode);
		setCompanyName(user.companyName);
		
		if (user.progress) {
			setAvatar("/img/avatar-1.png");
		}
	}, [user]);

	// User real-time listener
	useEffect(() => {
		if (!user?.id) return;
		
		const userRef = doc(db, "users", user.id);
		const unsubscribe = onSnapshot(userRef, (doc) => {
			if (!isMounted.current) return;
			
			const userData = doc.data();
			if (!userData) return;
			
			setUser(prevUser => ({
				...prevUser,
				...userData
			}));
			
			getAirdropList({...user, ...userData});
		});

		// Initial fetch
		getAirdropList(user);

		return () => unsubscribe();
	}, [user.id]);

	const voucherUsed = () => {
		setVoucher("You have no vouchers to use");
	};

	const handleClickSocial = () => {
		setFire(true);
		setPopup(false);
		window.open(
			"https://www.instagram.com/envyapples/?hl=en",
			"_blank",
			"fullscreen=yes"
		);
	};

	const handleShowDetails = () => {
		buttonForDetails === "Show Details"
			? setButtonForDetails("Back to card")
			: setButtonForDetails("Show Details");
		setShowDetails(!showDetails);
	};

	const classChanger = (e, heading) => {
		setPopupContent(e.target["src"]);
		setPopupHeading(heading);
		setClassChange(!classChange);
		if (heading === "Avatar") {
			setPopupText("Your unique avatar has been unlocked.");
			setPopupAdd("");
		} else if (heading === "Quiz") {
			setPopupText(
				`Your drink of choice is ${user.progress.packet_1.activities.quiz.answer}`
			);
			setPopupEmoji("🥂");
			setPopupAdd("");
		} else if (heading === "Mint") {
			setPopupText("You can also access your minted NFT in Solana");
			setPopupAdd("");
		} else if (heading === "Free drink") {
			setPopupContent(
				"https://ucarecdn.com/26395a81-c280-4b16-8ebd-ecfaea465ae9/-/crop/1632x917/0,0/-/preview/-/quality/lighter/-/format/auto/-/scale_crop/1920x1080/center/"
			);
			setPopupText("Follow given instructions to redeem the code");
		} else if (heading === "PDF") {
			setPopupEmoji("📄");
			setPopupText("");
			setPopupAdd(
				<button className="download-btn" onClick={handleClickDownload}>
					View PDF
				</button>
			);
		} else if (heading === "Video") {
			setPopupEmoji("🎥");
			setPopupText("");
			setPopupAdd(
				<div className="video-wrapper">
					<ReactPlayer
						controls
						config={{
							vimeo: {
								playerOptions: {
									autoplay: false,
									background: false,
								},
							},
						}}
						width="100%"
						height="165%"
						url="https://vimeo.com/738487002"
					/>
				</div>
			);
		} else if (heading === "Company") {
			setPopupText("");
			setPopupAdd(
				<div>
					<div className="company-details">
						Your company is <span id="company-name">{user.companyName}</span>
					</div>
					<div className="company-details">
						Your corporate email is: {user.corporateEmail}
					</div>
				</div>
			);
			setPopupEmoji("🏢");
		}
	};

	useEffect(() => {
		if (classChange) {
			setPopup(true);
		} else {
			setPopup(false);
		}
	}, [classChange]);
	var i = 0;
	var sentMail = false;


	useEffect(() => {

		var progressBars = [];

		for (let key in percentages) {

			let name = 'Module';
			if (DATA && DATA['PACKETS'] && DATA['PACKETS'][key])
				name = DATA['PACKETS'][key].name;

			progressBars.push({
				percentage: percentages[key],

				//background: "linear-gradient(180deg, #e337ff 0%, #882aff 100%)",

				text: name,
				points: ""
			});
		}
		setProgressBars(progressBars);

	}, [percentages]);


	const reward = async (reward) => {
		if (Cookies.get().id) {
			if (reward.name != '') {

				user.rewards.push({
					name: reward.name,
					price: reward.price,
					image: reward.image ? reward.image : '',
					redeemed: false,
					instruction: reward?.instruction,
				});

				const user_ref = await doc(db, "users", Cookies.get().id);
				await updateDoc(user_ref, {
					rewards: user.rewards,
					progress: user.progress,
				});
			}
		}
	}

	const setModuleCompleteNotification = () => {
		// alert('MODULE COMPLETED')
		setTimeout(() => {
			// setNotificationMessage("You've unlocked a new module!");

			// console.log('alert');

			setModuleNotification(true);
			// setPopup(true)
			// setFire(true);

			// console.log('MODULE NOTIFICATION: ' + moduleNotification);
			// console.log(popup);
		}, 500)

	}


	useEffect(() => {
		console.log('MODULE NOTIFICATION: ' + moduleNotification);
	}, [moduleNotification]);

	useEffect(() => {

		setV(v + 1);

		const update = async (progress_packet_key) => {


			// alert('UPDATE FUNCTION IN PROFILE');
			//console.log(progress_packet);
			//console.log(progress_packet_key);

			// await setModuleCompleteNotification();

			// setNotificationMessage("You've unlocked a new module!");

			console.log('alert');

			// setModuleNotification(true);
			// setPopup(true)
			// setFire(true);

			badges[progress_packet_key] = "is_awarded";

			user.progress[user.project_id]['packets'][progress_packet_key].badge.is_awarded = true;
			setUser(user);

			// let id = Cookies.get().id;
			// if (id){
			// 	const user_ref = await doc(db, "users", Cookies.get().id);

			await updateProgress();

			// setNotification(false);
			// setNotification(true);
			// setPopup(true)

			// setFire(false);
			// }

		};

		if (user && user.progress && user.progress[user.project_id] && !DATA['defaultData'] && !DATA['auth']) {

			console.log('set badges and progress (profile)');

			var percentages = [];
			var progress = [];
			var points = 0;
			var badges = [];


			var max_points = 0;
			if (DATA['PACKETS']) {
				DATA['PACKETS'].forEach((packet, i) => {
					if (packet.activities) {
						packet.activities.forEach((activity, y) => {
							if (activity.points) {
								max_points += parseInt(activity.points);
							}
						});
					}
				});
			}
			//console.log('max_points = '+ max_points);


			//console.log(user.progress);
			//console.log(user.project_id);

			var project_completed = true;

			for (let key in user.progress[user.project_id]['packets']) {

				if (key != 'false') {

					var progress_packet = user.progress[user.project_id]['packets'][key];

					percentages.push((progress_packet.package_points * 100) / max_points);

					//console.log(percentages);

					/*
					if (key == 0)
						percentages.push((progress_packet.package_points / 20) * 44);
					else
						percentages.push((progress_packet.package_points / 25) * 56);
					*/

					progress.push(progress_packet.package_points);

					points += progress_packet.package_points;

					// debugger;
					badges.push(
						progress_packet && progress_packet.badge && progress_packet.badge.is_awarded
							? "is_awarded"
							: progress_packet && progress_packet.badge && progress_packet.badge.is_unlocked
								? "is_activated"
								: "locked"
					);



					var completed = true;

					Object.values(progress_packet.activities).forEach((activity, activity_index) => {

						//console.log('@');
						//console.log(DATA);
						//console.log(user.project_id);
						//console.log(user.progress[user.project_id]);
						//console.log(user.progress);
						//console.log(key);

						if (DATA['PACKETS'] && DATA['PACKETS'][key] && DATA['PACKETS'][key]['activities'][activity_index]) {

							if (activity) {
								if (activity.completed !== true) {
									completed = false;
									// project_completed
								}
							}
						}
					});

					//console.log(1);

					if (!progress_packet.badge.is_awarded && completed) {

						//console.log(2);

						//console.log(progress_packet);

						console.log('completed')
						update(key);

					}
				}

				if (project_completed) {

					console.log('project completed');

					/*
					if (DATA['reward'] && DATA['reward']['name'] && !user.progress[DATA.ID]['reward']){
						console.log('project completed reward');

						reward(DATA['reward']);
					}
					*/

					console.log(DATA['rewards']);

					if (!user.progress[DATA.ID]['reward'] && DATA['rewards'] && DATA['rewards'].length > 0) {
						console.log('#1');

						for (key in DATA['rewards']) {
							console.log('#2');
							if (DATA['rewards'][key]['name'] != '') {
								console.log('#3');
								reward(DATA['rewards'][key]);
							}
						}
						user.progress[DATA.ID]['reward'] = true;
					}
				}
			}

			console.log('calculate progress bar');

			setPercentages(percentages);
			setProgress(progress);
			setBadges(badges);

			setUserPoints(
				`${points === 0
					? ""
					: `${points}`
				}`
			)

			setTotalPoints(
				`${points === 0
					? ""
					: `${points}`
				}`
			);
		}

	}, [user, user.project_id, user.setprogress, DATA]);

	useEffect(() => {
		if (user) {
			setImage(user.logoUrl);
			setVoucher(user.drinkCode);
			setCompanyName(user.companyName);
		}
		if (user.progress) {
			setAvatar("/img/avatar-1.png");
		}
	}, [user]);

	useEffect(() => {
		const unsub = onSnapshot(
			query(collection(db, "users"), orderBy("score", "desc")),
			(snapshot) => {

				let users = [];
				let items = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				for (let key in items) {
					if (items[key].progress && user.project_id && items[key].progress[user.project_id])
						users.push(items[key]);
				}
				setUsers(users);
			}
		);
		return () => unsub();
		/*
		const unsub = onSnapshot(
			query(collection(db, "users"), orderBy("score", "desc")),
			(snapshot) => {
				setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
			}
		);
		return () => unsub();
		*/
	}, [user.project_id]);


	// TEMP FIX (MISHA)
	useEffect(() => {
		if (user) {
			//setRating(i + 1);
			// setRating(i + 1);
			setScore(user.score);
			setUserAvatar(user.avatarUrl);
			setName(user.firstName)
		}
	}, [user]);



	useEffect(() => {
		if (users) {
			users.forEach((user, i) => {
				if (user.id === cookie) {
					setRating(i + 1);
					// setScore(user.score);
					// setUserAvatar(user.avatarUrl);
					// setName(user.firstName);
					// setUser(user);
				}
			});
		}
	}, [users]);


	const [isLoading, setIsLoading] = useState(true);

	const getAirdropList = async (userData) => {
		console.log("Fetching airdrops for user:", userData.id);
		if (userData && userData.AIRDROPS && userData.project_id) {
			setIsLoading(true);
			var filteredAirdrops = [];
			for (let airdrop of userData.AIRDROPS) {
				if (airdrop["projectID"] === userData.project_id) {
					let snap = await getDoc(doc(db, "projects", airdrop["projectID"]));
					let project = snap.data();

					if (project['AIRDROPS'] && project['AIRDROPS'][airdrop["airdropID"]]) {
						let airdropData = project['AIRDROPS'][airdrop["airdropID"]];

						if (
							airdropData.allUsers ||
							airdropData.filterType === 'all' ||
							(airdropData.filterType === 'minPoints' && userData.score >= (airdropData.minPoints || 0)) ||
							(airdropData.filterType === 'maxPoints' && userData.score <= (airdropData.maxPoints || Infinity)) ||
							(airdropData.filterType === 'activity' && isActivityCompleted(userData, airdropData.selectedActivity))
						) {
							filteredAirdrops.push(airdropData);
						}
					}
				}
			}
			console.log("Filtered airdrops:", filteredAirdrops);
			setAirdrops(filteredAirdrops);
			setIsLoading(false);
		}
	}

	useEffect(() => {
		if (user && user.id) {
			console.log("Setting up real-time listener for user:", user.id);
			const userRef = doc(db, "users", user.id);
			const unsubscribe = onSnapshot(userRef, (doc) => {
				const userData = doc.data();
				console.log("User data updated:", userData);
				// Merge the new data with existing user data
				setUser(prevUser => {
                    const updatedUser = {...prevUser, ...userData};
                    // Update score only if it's defined in the new data
                    if (userData.score !== undefined) {
                        setScore(userData.score);
                    }
                    return updatedUser;
                });
				getAirdropList({...user, ...userData});
			});

			// Initial fetch
			getAirdropList(user);

			return () => unsubscribe();
		}
	}, [user.id]);

	useEffect(() => {

		getAirdropList(user);

	}, [user]);

	useEffect(() => {
		if (airdrops.length > 0 && user && user.project_id) {
			console.log('airdrop - loooooook here!!!!');
			console.log("Filtering airdrops");
			console.log(user);
			const filtered = airdrops.filter(airdrop => {
				console.log('airdrop - loooooook here!!!!');
				console.log(airdrop);

				// Check if the airdrop is for all users or if filterType is 'all'
				if (airdrop.allUsers || airdrop.filterType === 'all') return true;

				// Check if the airdrop is specific to the current project
				if (airdrop.projectId === user.project_id) return true;

				// Check if the airdrop matches the user's points
				if (airdrop.filterType === 'minPoints' && user.score >= (airdrop.minPoints || 0)) return true;
				if (airdrop.filterType === 'maxPoints' && user.score <= (airdrop.maxPoints || Infinity)) return true;

				// Check if the airdrop is for a specific activity
				if (airdrop.filterType === 'activity' && airdrop.selectedActivity && user.progress && user.progress[user.project_id]) {
					const [packetIndex, activityIndex] = airdrop.selectedActivity.split('-');
					const activityCompleted = user.progress[user.project_id].packets[packetIndex]?.activities[activityIndex]?.completed;
					return activityCompleted;
				}

				return false;
			});

			console.log("Filtered airdrops:", filtered);
			setFilteredAirdrops(filtered);
		}
	}, [airdrops, user, DATA]);


	// Helper function to check if an activity is completed
	const isActivityCompleted = (user, selectedActivity) => {
		if (user.progress && user.progress[user.project_id]) {
			const [packetIndex, activityIndex] = selectedActivity.split('-');
			return user.progress[user.project_id].packets[packetIndex]?.activities[activityIndex]?.completed || false;
		}
		return false;
	}


	function onModalChange(newState) {
		setPopup(newState);
	}


	return (
		<>

			<div className="profile">
				<ConfettiComp fire={fire} />
				{user.avatarRevealed ? (
					<HeaderGreetings name={name} avatar={userAvatar} score={score} />
				) : (
					<HeaderGreetings name={name} avatar={avatar} score={score} />
				)}

				<InAppNotification
					onModalChange={onNotificationChange}
					trigger={moduleNotification}
					buttonText={notificationButtonText}
					navigateTo={notificationButtonUrl}
				>
					<div>
						{/* <h2>In-App Notification!</h2> */}
						<p>{notificationMessage}</p>

						<button>
							Unlock module
						</button>

					</div>
				</InAppNotification>

				<PopUpMessage trigger={popup} onModalChange={onModalChange}>
					{
						<>
							<div
								className={`wallet-card ${!showDetails ? "" : "hide-details"}`}
							>
								{popupContent ? (
									<img className="wallet-image" src={popupContent} alt="" />
								) : (
									<div className="emoji">{popupEmoji}</div>
								)}
							</div>
							<div className={`flip-side ${!showDetails ? "" : "hide-details"}`}>
								<h2>{popupHeading} details: </h2>
								<div className="popup-text">{popupText}</div>
								{popupAdd}
							</div>
							{/* <button className="show-details" onClick={handleShowDetails}>
								{buttonForDetails}
							</button> */}
						</>
					}
				</PopUpMessage>
				<div style={{ marginTop: "20px" }} className="wallet-btn-wrapper">
					<BusinessCard />
					{/* <a className="applewalletcard" href="https://pub1.pskt.io/t/ey2a2h" target="_blank">
						<img className="applewalletcard" src={AppleSvg}></img>
					</a> */}
				</div>

				<div className="profile-wrapper">
					{/* <div className="dash"></div> */}
					<p className="profile-titles">Your collectibles</p>

					<Wallet />
					<AirdropsList airdrops={filteredAirdrops} />

					{!showProjects && DATA['PACKETS'] ?

						<>
							<p className="profile-titles mb-11">Your stats </p>
							<Score score={score} rating={rating} />
							<div className="badges">
								<p className="profile-titles">Badges </p>

								<p style={{ marginTop: "-10px" }} className="home-titles">Rewards progresss </p>
								{/* <ProgressBar
										visualParts={{
											percentage1: percentage1,
											percentage2: percentage2,
											points1: progress1,
											points1: progress2,
											totalPoints: totalPoints,
											background: "linear-gradient(to right, #e337ff, #882aff)",
											text: "",
										}}
									/> */}
								{/* // Older */}


								<ProgressBar2
									totalPoints={100}
									visualParts={progressBars}
								/>

								{!props.showAllBadges ?

									<>
										<BadgesUnlocked badges={badges} />
									</>
									: null
								}

								{props.showAllBadges ?

									<>
										<Badges badges={badges} />
									</>
									: null
								}


							</div>
						</>
						:
						null

					}



				</div>
				{/* <button classname="logout-btn" onClick={sendEmail}>
					Send Email
				</button> */}
				{/* <button className="logout-btn" onClick={remove}>
					Logout
				</button> */}
				{/* <Footer /> */}
			</div>
		</>
	);
};

export default Profile;
