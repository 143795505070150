import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import ArrowLeft from "./ArrowLeft";

const Qr = ({ clickedCamera }) => {
	const [data, setData] = useState("");
	useEffect(() => {
		if (data) window.location.href = data;
	}, [data]);

	return (
		<div className="qr">
			<header>
				<div className="top">
					<ArrowLeft />
					Scan QR
				</div>
			</header>
			<QrReader
				constraints={{ facingMode: "environment" }}
				className="cameraInput"
				onResult={(result, error) => {
					if (!!result) {
						setData(result?.text);
					}
				}}
				style={{ width: "200%", height: "200vh" }}
			/>
			<h1 className="qr-instruction">Scan the QR code to unlock new tasks. </h1>
			<p className="qr-extra">Experiencing issues? Use your phone camera app instead.</p>
		</div>
	);
};

export default Qr;
