import React, { useContext, useEffect, useRef } from "react";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserContext } from "./User";
import { DataContext } from "./DataContext";
import Cookies from "js-cookie";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import db from "../firebase";
import safeCookieOperations from '../utils/cookieUtils';

export default function ProtectedRoute({ children }) {
	const { DATA, setDATA } = useContext(DataContext);
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const { code } = useParams();
	const [searchParams] = useSearchParams();
	
	// Add mounted ref
	const isMounted = useRef(true);

	// Cleanup on unmount
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	// Handle cookie and DATA updates
	useEffect(() => {
		const updateCookiesAndData = () => {
			try {
				// Safely handle undefined ID cookie
				const currentId = safeCookieOperations.get('id');
				if (currentId === 'undefined') {
					safeCookieOperations.remove("id");
				}

				// Handle project ID in DATA context
				if (code && isMounted.current) {
					setDATA(prevData => ({
						...prevData,
						projectId: code
					}));
				}

				// Handle unlock parameter safely
				const unlock = searchParams.get("unlock");
				if (unlock) {
					safeCookieOperations.set('unlock', unlock, { path: '/' });
				} else if (code && code !== safeCookieOperations.get('projectId')) {
					safeCookieOperations.remove('unlock');
				}

				// Set project ID cookie safely
				if (code) {
					safeCookieOperations.set('projectId', code, { path: '/' });
				}
			} catch (error) {
				console.error('Error updating cookies and data:', error);
			}
		};

		updateCookiesAndData();
	}, [code, searchParams]);

	// Handle last login updates
	useEffect(() => {
		const handleLastLogin = async () => {
			try {
				const currentId = safeCookieOperations.get('id');
				const lastLogin = safeCookieOperations.get('lastLogin');

				if (!currentId) return;

				const now = Date.now();
				const shouldUpdateLogin = !lastLogin || 
					(Math.floor((now - lastLogin) / 1000) / 3600) > 1;

				if (shouldUpdateLogin && isMounted.current) {
					safeCookieOperations.set("lastLogin", now);
					const user_ref = doc(db, "users", currentId);
					await updateDoc(user_ref, {
						lastLogin: now,
					});
				}
			} catch (error) {
				console.error('Error handling last login:', error);
			}
		};

		handleLastLogin();
	}, []);

	// Project access check
	useEffect(() => {
		const checkAccess = () => {
			try {
				const currentId = safeCookieOperations.get('id');
				if (!currentId || !code || !user || !user.project_id) return;

				if (user.project_id !== code && isMounted.current) {
					// Safely remove notification cookies
					const allCookies = safeCookieOperations.getAll();
					Object.keys(allCookies).forEach(cookieName => {
						if (cookieName.startsWith('moduleNotificationShown_') || 
							cookieName.startsWith('moduleCompletionShown_')) {
							safeCookieOperations.remove(cookieName);
						}
					});

					// Remove specific cookies safely
					const cookiesToRemove = [
						"lastLogin",
						"didShowProgressNotificationModuleOne",
						"showProgressNotificationModuleOne",
						"profileComplete",
						"giftCardEarnedShow",
						"phoneNumber",
						"id"
					];

					cookiesToRemove.forEach(cookie => {
						safeCookieOperations.remove(cookie);
					});

					navigate('/signup', { replace: true });
				}
			} catch (error) {
				console.error('Error checking access:', error);
			}
		};

		checkAccess();
	}, [user, code]);

	// Basic auth check with safe cookie operation
	const Auth = safeCookieOperations.get('id') ? true : false;

	if (!Auth) {
		return <Navigate to="/signup" />;
	}

	// Add this effect at the top with other effects
	useEffect(() => {
		const validateUser = async () => {
			const currentId = safeCookieOperations.get('id');
			if (!currentId) return;

			try {
				const userDoc = doc(db, "users", currentId);
				const userSnap = await getDoc(userDoc);
				
				if (!userSnap.exists() && isMounted.current) {
					// Clear cookies and redirect if user doesn't exist
					const cookiesToRemove = [
						"lastLogin",
						"didShowProgressNotificationModuleOne",
						"showProgressNotificationModuleOne",
						"profileComplete",
						"giftCardEarnedShow",
						"phoneNumber",
						"id"
					];

					cookiesToRemove.forEach(cookie => {
						safeCookieOperations.remove(cookie);
					});
					navigate('/signup', { replace: true });
				}
			} catch (error) {
				console.error('Error validating user:', error);
			}
		};

		validateUser();
	}, [navigate]);

	return children;
}
