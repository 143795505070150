import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { DataContext } from "./DataContext";
// import "../styles/PopUpMessage.css";

const PopUpMessage = (props) => {
	const { DATA, setDATA } = useContext(DataContext);
	const navigate = useNavigate();
	const [close, setClose] = useState(false);

	const logProps = () => {
		console.log(props)
	}

	const handleClick = () => {

		if (props.closeModal){

			props.closeModal();

		} else {

			//console.log(DATA);

			// setClose(true);
			if (props && props.onModalChange)
				props.onModalChange(false);

			let url = '/';
			if (DATA && DATA['ID']);
				url = '/'+DATA['ID']+'/';

			//console.log('url='+url);

			navigate(url);
		}
	};

	const handleClickSimpleClose = () => {
		// setClose(true);
		props.onModalChange(false);
	};

	useEffect(() => {
		// setClose(props.modalOpen)
		// console.log()
	}, [props.trigger]);

	// const modalCloser = () => {
	// 	if (props.modalCloser) {
	// 		props.modalCloser(!modalCloser);
	// 	} else {
	// 		console.log(props);
	// 		// document
	// 		// 	.getElementsByClassName("popup")
	// 		// 	.classList.remove("visible")
	// 		// 	.classList.add("hidden");
	// 	}
	// 	setClose(true);
	// };

	return props.trigger ? (
		<div className={"visible popup"}>
			<div className="module-closer" onClick={handleClickSimpleClose}>
				<svg className="close-modal-icon" xmlns="http://www.w3.org/2000/svg" height="38" width="38"><path fill="#fff" d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg>
			</div>
			<div className="popup-inner rounded">
				{props.children}
				<br />
				{!props.hideButton && (
					<button
						className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
						onClick={handleClick}
					>
						Ok
					</button>
				)}
			</div>
		</div>
	) : (
		""
	);
};

export default PopUpMessage;
