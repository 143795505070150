import Cookies from 'js-cookie';

const safeCookieOperations = {
    get: (name) => {
        try {
            return Cookies.get(name);
        } catch (error) {
            console.error(`Error getting cookie ${name}:`, error);
            return null;
        }
    },

    set: (name, value, options = {}) => {
        try {
            Cookies.set(name, value, options);
        } catch (error) {
            console.error(`Error setting cookie ${name}:`, error);
        }
    },

    remove: (name, options = {}) => {
        try {
            Cookies.remove(name, options);
        } catch (error) {
            console.error(`Error removing cookie ${name}:`, error);
        }
    },

    getAll: () => {
        try {
            return Cookies.get();
        } catch (error) {
            console.error('Error getting all cookies:', error);
            return {};
        }
    }
};

export default safeCookieOperations; 