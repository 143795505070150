import React, { useEffect, useState, useContext } from "react";
import db from "../firebase";
import {
	onSnapshot,
	collection,
	query,
	getDocs,
	updateDoc,
	doc,
	serverTimestamp,
	getAll,
	addDoc,
	setDoc,
	getDoc,
} from "firebase/firestore";
import FoundMatch from "./FoundMatch";
import TaskCard from "./TaskCard";
import HomeButton from "./HomeButton";
import PopUpMessage from "./PopUpMessage";
import { UserContext } from "./User";
import { useParams, useNavigate, useLocation } from "react-router";
import useUpdateEffect from "../hooks/useUpdateEffect";
import Cookies from "js-cookie";
import { compileString } from "sass";
import ArrowLeft from "./ArrowLeft";
import Footer from "./Footer";
import { DataContext } from "./DataContext";

// import SingleCard from './SingleCard'
// import './MemoryGame.css'


const cardImages = [
	{ src: "/img/helmet-1.png", id: 1 },
	{ src: "/img/potion-1.png", id: 2 },
	{ src: "/img/ring-1.png", id: 3 },
	{ src: "/img/scroll-1.png", id: 4 },
	{ src: "/img/shield-1.png", id: 5 },
	{ src: "/img/sword-1.png", id: 9 },
	{ src: "/img/squidward.jpeg", id: 6 },
	{ src: "/img/sponge.png", id: 7 },
	{ src: "/img/shield-1.png", id: 8 },
];

const MemoryGame = () => {

	const {DATA, setData} = useContext(DataContext);

	const location = useLocation();
	const { packet_id } = location.state;
	const { activity_i } = useParams();
	const activity_index = activity_i - 1;
	const packet_index = parseInt(packet_id.replace(/packet_/,'')-1);
	const { points } = location.state;

	const { user } = useContext(UserContext);
	const id = user.id;
	const [fourNums, setFourNums] = useState("");
	const [matchInMemory, setMatchInMemory] = useState(null);
	const [users, setUsers] = useState([]);
	const [image, setImage] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [nonEmptyMemory, setNonEmptyMemory] = useState(false);
	const [isLotteryWinner, setIsLotteryWinner] = useState(false);
	const [[mins, secs], setTime] = useState([]);
	const [timeStamp, setTimeStamp] = useState();
	const [myMatch, setMyMatch] = useState();
	const navigate = useNavigate();
	const [lonelyNum, setLonelyNum] = useState([]);
	const [popUp, setPopUp] = useState(false);
	const [distributeCardsRan, setDistributeCardsRan] = useState(false);
	let userArray = users.map((user) => user);
	const timeRemaining = async () => {
		let timeNow = new Date();
		console.log(timeStamp);
		let secRemaining = (await (timeNow - timeStamp)) / 1000;
		let minRemaining =
			Math.floor(secRemaining / 60) < 0 ? 0 : Math.floor(secRemaining / 60);
		secRemaining =
			Math.floor(secRemaining % 60) < 0 ? 0 : Math.floor(secRemaining % 60);
		console.log([minRemaining, secRemaining]);
		setTime([59 - minRemaining, 59 - secRemaining]);
		return [minRemaining, secRemaining];
	};

	const [lonelyUser, setLonelyUser] = useState({});
	const [lonelyId, setLonelyId] = useState("");
	// const gameStartDocRef = doc(db, "memory", "gameStart");
	// console.log(gameStartDocRef);
	const [count, setCount] = useState(0);

	const taskDescription =
		'You need to find a user with the same picture, hit the "Found Match" button and insert the code. Make sure you do it before clock runs out!';

	// A few bugs with this useeffect right now. 1.) Doesn't seem like we have the correct dependencies in the dependency array. 2.) when we hit reset button in dashboard and come to memorygame we get an error for line 73 'can't read property matchNumber' so I'm assuming it doesn't know this field exists yet so we have to refresh in order for it to run.

	useEffect(() => {
		if (count === 2) {
			// if(gameStartDocRef) {
			const getMemoryDoc = async () => {
				let response = "misha";
				// let content = 'adrian';
				await getDoc(doc(db, "memory", "gameStart"))
					.then((resp) => {
						response = resp;
						return response;
					})
					.catch((error) => {
						console.log(error.message);
					});
			};

			// const getLonelyNumber = async () => {
			// 	// const memoryDocRef = await getDoc(doc(db, "memory", "gameStart"));
			// 	console.log("I am lonely user and id", lonelyUser, lonelyId);
			// 	await getMemoryDoc().then((memoryDocRef) => {
			// 		// debugger;
			// 		// console.log(memoryDocRef.data().matchNumber)
			// 		// const matchNumsValues = Object.values(
			// 		// 	memoryDocRef.data().matchNumber
			// 		// );
			// 		// const oddOneOut = matchNumsValues.filter(
			// 		// 	(num, i) =>
			// 		// 		matchNumsValues.indexOf(num) === matchNumsValues.lastIndexOf(num)
			// 		// );

			// 		// setLonelyNum(oddOneOut[0]);

			// 		const findLonelyUser = memoryDocRef.data().matchNumber;
			// 		for (const [key, value] of Object.entries(findLonelyUser)) {
			// 			if (value === lonelyNum) {
			// 				setLonelyUser({ key, value });
			// 				// const lotteryDocRef = doc(db, "memory", "gameStart");
			// 				// updateDoc(lotteryDocRef, {
			// 				// 	lotteryWinner: lonelyUser,
			// 				// });
			// 			}
			// 		}
			// 		console.log("I am the lonelyUser in loop", lonelyUser);
			// 		// let id;
			// 		for (const dbUser of userArray) {
			// 			if (dbUser.companyId === lonelyUser.key) {
			// 				setLonelyId(dbUser.id);

			// 				// const id = lonelyId;
			// 				// const userDocRef = doc(db, "users", id);
			// 				// updateDoc(userDocRef, {
			// 				// 	lotteryWinner: true,
			// 				// });
			// 				// id = dbUser.id
			// 			}
			// 		}
			// 		console.log("I AM LONELY USER ID", lonelyId);
			// 	});

			// 	// need to make sure this check is running.
			// 	if (lonelyId) {
			// 		console.count("hello!!!!!");
			// 		const id = lonelyId;
			// 		const lotteryDocRef = doc(db, "memory", "gameStart");
			// 		const userDocRef = doc(db, "users", id);
			// 		updateDoc(lotteryDocRef, {
			// 			lotteryWinner: lonelyUser,
			// 		});
			// 		updateDoc(userDocRef, {
			// 			lotteryWinner: true,
			// 		});
			// 	}
			// 	// setIsLotteryWinner(true)
			// 	// setLotteryWinner(true);
			// 	if (user.lotteryWinner) {
			// 		setPopUp(true);
			// 	}
			// };

			// getLonelyNumber();
			setCount(count + 1);
			// }
		} else if (count === 1) {
			setCount(count + 1);
		}
		// if(distributeCardsRan === true) {
		// 	getLonelyNumber()
		// }
	}, [count, lonelyId, distributeCardsRan]);

	// console.log('I AM ALL USERS', userArray)

	useEffect(() => {
		console.log(nonEmptyMemory);
		console.log(users);
		if (nonEmptyMemory && !timeStamp && users.length !== 0) {
			console.log("WAYAYAYY");
			onSnapshot(doc(db, "memory", "createdAt"), (doc) => {
				setTimeStamp(doc.data());
				console.log("from UE_______", timeStamp);
			});
		}

		if (users.length === 0) {
			console.log("onsnapshot loop");
			onSnapshot(collection(db, "users"), (snapshot) =>
				setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
			);
			setNonEmptyMemory(onSnapshot(collection(db, "memory")) ? true : false);
		} else if (users.length !== 0 && !timeStamp) {
			const distributeCards = async () => {
				const timeCreation = serverTimestamp();
				let snap = await getDoc(doc(db, "memory", "gameStart"));
				let number;
				setNonEmptyMemory(true);
				if (!snap.data()) {
					console.log(snap.data());
					const matchNum = {};
					const memory = {};
					const shuffledArray = userArray.sort(() => 0.5 - Math.random());
					// shuffle users before assigning match numbers.
					for (let i = 0; i < shuffledArray.length; i++) {
						let randomNumber = Math.floor(1000 + Math.random() * 9000);
						number =
							i < shuffledArray.length / 2 ? i : shuffledArray.length - i - 1;
						memory[shuffledArray[i].email] = randomNumber;
						matchNum[shuffledArray[i].email] = number;
					}
					const memDocRef = await setDoc(doc(db, "memory", "gameStart"), {
						fourNums: memory,
						matchNum: matchNum,
						createdAt: timeCreation,
					}).then(async () => {
						snap = await getDoc(doc(db, "memory", "gameStart"));
						console.log(snap.data().fourNums[user.email]);
						setFourNums(snap.data().fourNums[user.email]);
						setMyMatch(snap.data().matchNum[user.email]);
						setMatchInMemory(matchNum);
						setTime([60, 0]);
					});
				} else {
					console.log(snap.data());
					setFourNums(snap.data().fourNums[user.email]);
					setMyMatch(snap.data().matchNum[user.email]);
					setTimeStamp(snap.data().createdAt.toDate());
				}
			};

			distributeCards();
			setDistributeCardsRan(true);
		} else if (timeStamp) {
			console.log("^^^^^^^^^^");
			const setEverything = async () => {
				let snap = await getDoc(doc(db, "memory", "gameStart"));
				setFourNums(snap.data().fourNums[user.email]);
				setMyMatch(snap.data().matchNum[user.email]);
				timeRemaining();
			};
			setEverything();
		}
	}, [users, timeStamp]);
	const update = async () => {
		const user_ref = await doc(db, "users", Cookies.get().id);

		user.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed = true;
		if (DATA['PACKETS'][packet_index].activities[activity_index]['reward']){

			let reward = DATA['PACKETS'][packet_index].activities[activity_index]['reward'];

			if (reward.name != ''){
				user.rewards.push({
					name: reward.name,
					price: reward.price,
					image: reward.image,
					redeemed: false,
					instruction: reward?.instruction,
				});
			}
		}

		user.setprogress = false;
		setUser(Object.assign({}, user));

		await updateDoc(user_ref, {
			progress: user.progress,
			rewards: user.rewards,
		});
	};
	// DEPENDENCIES: uses, nonEmptyMemory, timeStamp, fourNums
	const [timeIsUp, setTimeIsUp] = useState(false);
	useEffect(() => {
		const out = () => {
			setTimeIsUp(true);
			update();
			setPopUp(true);
			// navigate("/dashboard");
		};
		const timer = () => {
			if (mins < 0 || (mins === 0 && secs === 0)) {
				out();
			} else if (secs === 0) {
				setTime([mins - 1, 59]);
			} else {
				setTime([mins, secs - 1]);
			}
		};
		const timerId = setInterval(() => timer(), 1000);
		return () => clearInterval(timerId);
	}, [mins, secs]);

	useEffect(() => {
		if (myMatch == 0) setImage("/img/helmet-1.png");
		if (myMatch < 8) setImage(cardImages[myMatch].src);
		if (myMatch >= 8) setImage(cardImages[myMatch % 8].src);
		console.log(myMatch);
		// debugger;
	}, [myMatch]);
	const showTimer =
		mins >= 0
			? `${mins.toString().padStart(2, "0")}:${secs
					.toString()
					.padStart(2, "0")}`
			: "";

	return (
		<div className="w-full h-full flex items-center flex-col font-extrabold text-3xl memory-game">
			{/* <div>4 digit code: {fourNums} - updating from db now</div> */}
			<header>
					<div className="top">
						<ArrowLeft />
						Memory Game
					</div>
				</header>
			<div>
				<img
					key={cardImages.id}
					className="block ml-auto mr-auto"
					src={image}
					alt="user card"
				/>
			</div>
			<div className="timer-wrap">
				<div className="timer">
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
							stroke="#9087E5"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M8 4.66675V8.00008L10 10.0001"
							stroke="#9087E5"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					{showTimer}
				</div>
			</div>
			<TaskCard taskDescription={taskDescription} />
			<button onClick={() => setOpenModal(true)} className="memory-foundMatch">
				Found Match
			</button>

			<div></div>
			{openModal && (
				<FoundMatch
					users={users}
					time={[mins, secs]}
					closeModal={setOpenModal}
					code={fourNums}
				/>
			)}
			<PopUpMessage trigger={popUp}>
				{timeIsUp === true ? (
					<h2>Sorry, your time is up!</h2>
				) : (
					<h2>Congratulations You've won the lottery!!</h2>
				)}
			</PopUpMessage>

			<Footer />
		</div>
	);
};

export default MemoryGame;
