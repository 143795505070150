import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

// have to implement logic to start timer when timestamp is created.

// try moving this into memorygame as a function instead of a component.
const Timer = ({ time }) => {
	const [[mins, secs], setTime] = useState([]);
	const navigate = useNavigate();
	const showTimer =
		mins >= 0
			? `${mins.toString().padStart(2, "0")}:${secs
					.toString()
					.padStart(2, "0")}`
			: "";

	useEffect(() => {
		if (!mins) {
			setTime([time.minutes, time.seconds]);
		} else {
			const out = () => {
				alert("Time is up!");
				navigate("/dashboard");
			};
			const timer = () => {
				if (mins < 0 || (mins === 0 && secs === 0)) {
					out();
				} else if (secs === 0) {
					setTime([mins - 1, 59]);
				} else {
					setTime([mins, secs - 1]);
				}
			};
			const timerId = setInterval(() => timer(), 1000);
			return () => clearInterval(timerId);
		}
	}, [time, mins, secs]);

	return (
		<>
			<div className="timer-wrap">
				<div className="timer">{showTimer}</div>
			</div>
		</>
	);
};

export default Timer;
