import react, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import DashboardProject from "./DashboardProject";
import DashboardProjects from "./DashboardProjects";

export default function Dashboard() {

    const navigate = useNavigate();
    const [showProjectsList, setShowProjectsList] = useState(false);

    useEffect(() => {

        if(Cookies.get('projectId')){

            console.log('###'+Cookies.get('projectId'));

            navigate("/"+Cookies.get('projectId')+'/');

        } else {

            setShowProjectsList (false);
        }

    }, []);

    return (

        <>

            {

                showProjectsList ?

                    <DashboardProjects />

                :

                    <DashboardProject />

            }

        </>
    );
}
