import React from "react";
import { Link } from "react-router-dom";

export default function ArrowLeft() {
	return (
		<>
			<div className="top">
				<Link to="/">
					<svg
						// id="dot"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9 17.5L3 12L9 6.5"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M5 12H21"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Link>
			</div>
		</>
	);
}
