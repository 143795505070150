import React, { useState, useEffect, useContext, useRef } from "react";
import { DataContext } from "./DataContext";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import SwiperCore, { Navigation } from 'swiper/core';
import Badge from "./Badge";

SwiperCore.use([Navigation]);

export default function BadgesUnlocked({badges}) {
	const [displayBadges, setDisplayBadges] = useState(badges);
	const [displayActivities, setDisplayActivities] = useState(false);
	const {DATA, setData} = useContext(DataContext);
	const [activeIndex, setActiveIndex] = useState(0);
	const [showSlider, setShowSlider] = useState(false);
	const lastValidBadgesRef = useRef(badges);

	useEffect(() => {
		if (badges && badges.length > 0) {
			lastValidBadgesRef.current = badges;
			setDisplayBadges(badges);
		}
	}, [badges]);

	useEffect(() => {
		if (DATA['unlock'])
			setActiveIndex(DATA['unlock']);
	}, [DATA, DATA['unlock']]);

	useEffect(() => {
		if (DATA.PACKETS && DATA.PACKETS.length > 3)
			setShowSlider(true);
	}, [DATA, DATA.PACKETS]);

	console.log('badges');
	console.log(lastValidBadgesRef.current);
	console.log(DATA);
	console.log(DATA.PACKETS);

	return (
		<>
			<div className="row badges">
				{lastValidBadgesRef.current &&
					lastValidBadgesRef.current
						.map((badgeStatus, index) => ({ badgeStatus, index }))
						.filter(({ badgeStatus }) => badgeStatus === 'is_awarded')
						.map(({ index }) => {
							const packet = DATA.PACKETS[index];
							return (
								<Badge 
									key={index} 
									packetIndex={index} 
									badges={lastValidBadgesRef.current} 
									packet={packet} 
								/>
							);
						})
				}
			</div>
		</>
	);
}
