import React from 'react';

export default function AirdropsList({airdrops}) {
    console.log("AirdropsList received:", airdrops);

    return (
        <div className="airdropsList">
            {airdrops.length > 0 ?
                airdrops.map((airdrop, airdropIndex) =>
                    <div className="airdrop__item" key={airdropIndex}>
                        <div className="airdrop__image"><img src={airdrop.image} alt={airdrop.name} /></div>
                    </div>
                )
                :
                <div className="empty-airdrops">
                    No airdrops yet
                </div>
            }
        </div>
    );
}