import React, { useContext, useEffect, useState } from "react";
import { doc, getDocs, getDoc, collection, onSnapshot, setDoc, updateDoc, addDoc } from "firebase/firestore";
import db from "../firebase";

import useNotifications from "../hooks/useNotifications";

export function DefaultData() {

    const Data = {
        defaultData: true,
        COLORS: [
            { name: 'Loader background', color: '#1a2076' },
            { name: 'Background', color: '#3c0d5b' },
            { name: 'Footer menu background', color: '#171032' },
            { name: 'Rewards and wallet background', color: '#0d0b1d' },
            { name: 'Rewards header background', color: '#510b77' },
            { name: 'Leaderboard background', color: '#882aff' },
            { name: 'Greeting text color', color: '#b63fff' },
            { name: 'Activity background', color: '#541D94' },
            { name: 'Activity text color', color: '#ffffff' },
            { name: 'Buttons background', color: '#b63fff' },
            { name: 'Buttons color', color: '#ffffff' },
            { name: 'Module names', color: '#ffffff' },
            { name: 'Quiz responses gradient color one', color: '#e337ff' },
            { name: 'Quiz responses gradient color two', color: '#882aff' },
            { name: 'Quiz responses text color', color: '#ffffff' },
        ],
        name: '',
        logo: '',
        PACKETS: [
        ],
        REWARDS: {
            /*
            reward_one: {
                id: "reward-one",
                name: "$1000 Notion credit",
                price: 5,
                image: "notion.png",
                coupon: "F6SBlackFriday",
                redeemed: false,
            },
            reward_two: {
                id: "reward-two",
                name: "Personalized AI gift",
                price: 2,
                image: "ai.png",
                coupon: "",
                redeemed: false,
            },
            */
        },
        PROGRESS: {
        },
    };

    return Data;

};


export function ProcessData(DATA) {


    //console.log('process data')
    //console.log(DATA);

    DATA['processed'] = true;

    if (DATA['COLORS'] !== undefined) {

        //console.log('colors');
        //console.log(DATA['COLORS']);

        const root = document.documentElement;

        root?.style.setProperty("--loader_background", DATA['COLORS'][0]['color']);

        root?.style.setProperty("--background", DATA['COLORS'][1]['color']);

        root?.style.setProperty("--footer_menu_background", DATA['COLORS'][2]['color']);
        root?.style.setProperty("--rewards_and_wallet_background", DATA['COLORS'][3]['color']);
        root?.style.setProperty("--rewards_header_background", DATA['COLORS'][4]['color']);

        if (DATA['COLORS'][5]) {
            root?.style.setProperty("--leaderboard_background", DATA['COLORS'][5]['color']);
            root?.style.setProperty("--greeting_text_color", DATA['COLORS'][6]['color']);
            root?.style.setProperty("--activity_background", DATA['COLORS'][7]['color']);
        }

        if (DATA['COLORS'][8]) {
            root?.style.setProperty("--activity_text_color", DATA['COLORS'][8]['color']);
        }

        if (DATA['COLORS'][9]) {
            root?.style.setProperty("--buttons_background", DATA['COLORS'][9]['color']);
            root?.style.setProperty("--buttons_color", DATA['COLORS'][10]['color']);
            root?.style.setProperty("--module_name", DATA['COLORS'][11]['color']);
            root?.style.setProperty("--response_gradient_color_one", DATA['COLORS'][12]['color']);
            root?.style.setProperty("--response_gradient_color_two", DATA['COLORS'][13]['color']);
            root?.style.setProperty("--response_text_color", DATA['COLORS'][14]['color']);
        }

        if (DATA['COLORS'][15]) {
            root?.style.setProperty("--nft_color_one", DATA['COLORS'][15]['color']);
            root?.style.setProperty("--nft_color_two", DATA['COLORS'][16]['color']);
            root?.style.setProperty("--nft_color_three", DATA['COLORS'][17]['color']);
        }

    }

    for (let key in DATA['PACKETS'])
        DATA['PACKETS'][key]['completed'] = false;


    //=============================================

    /*
    user.progress =
        {
            packet_1: {
                id: "9090909d9ru",
                activities: {
                    quiz: {
                        completed: false,
                        answer: "",
                    },
                    mint: {
                        completed: false,
                    },
                    video: {
                        completed: false,
                    },
                },
                badge: {
                    name: "World",
                    img: "ModuleOne",
                    is_unlocked: false,
                    is_awarded: false,
                },
                packet_activated: true,
                packet_completed: false,
                package_points: 0,
            },
            packet_2: {
                id: "9090909d9kz",
                activities: {
//                     quiz: {
//                         completed: false,
//                         answer: "",
//                     },
                    bio: {
                        completed: false,
                    },
                    ar: {
                        completed: false,
                    },
                    pdf: {
                        completed: false,
                    },
                },
                badge: {
                    name: "Identity",
                    img: "../assets/module-two.png",
                    is_unlocked: false,
                    is_awarded: false,
                },
                packet_activated: false,
                packet_completed: false,
                package_points: 0,
            },
            packets: [
                {
                    name: "World Building",
                    activities: [
                        {
                            name: "Quiz",
                            activity_completed: false,
                            id: 1,
                        },
                    ],
                    packet_completed: false,
                },
                {
                    name: "Identity Currency",
                    activities: [
                        {
                            name: "Ar",
                            activity_completed: false,
                            id: 2,
                        },
                    ],
                    packet_completed: false,
                },
            ]
        };
    */

    DATA['PROGRESS'] = {};
    DATA['PROGRESS']['packets'] = [];
    for (let key in DATA['PACKETS']) {

        /*
        DATA['PROGRESS']['packet_'+key] = {
        
            //id: "9090909d9ru",
            id: key, //project_id+'_'+key,
            badge: {
                name: "World",
                img: "ModuleOne",
                is_unlocked: false,
                is_awarded: false,
            },                        
            packet_completed: false,
            package_points: 0,                
        };
        

        if (key == 0)
            DATA['PROGRESS']['packet_'+key]['packet_activated'] = true;
        else
            DATA['PROGRESS']['packet_'+key]['packet_activated'] = false;
        
        DATA['PROGRESS']['packet_'+key]['activities'] = [];

        */

        DATA['PROGRESS']['packets'][key] = {
            packet: 'packet_' + key,
            name: DATA['PACKETS'][key]['name'],
            packet_completed: false,
            id: key,
            badge: {
                name: "World",
                img: "ModuleTwo",
                is_unlocked: false,
                is_awarded: false,
            },
            packet_completed: false,
            package_points: 0,
        };
        DATA['PROGRESS']['packets'][key]['activities'] = [];

        //if (key == 0)
        //    DATA['PROGRESS']['packets'][key]['packet_activated'] = true;
        //else

        DATA['PROGRESS']['packets'][key]['packet_activated'] = false;


        for (let key2 in DATA['PACKETS'][key]['activities']) {

            let activity = DATA['PACKETS'][key]['activities'][key2];

            //DATA['PROGRESS']['packet_'+key]['activities'][key2] = {completed: false};

            if (activity) {
                DATA['PROGRESS']['packets'][key]['activities'][key2] = {
                    name: activity['name'],
                    activity_completed: false,
                    id: key2,
                };
            } else {
                DATA['PROGRESS']['packets'][key]['activities'][key2] = false;
            }
        }
    }
    //=============================================

    console.log('Process DATA');
    //console.log(DATA);

    if (DATA.projectId &&  DATA.admin)
        useNotifications(DATA.projectId, DATA.admin);

    return DATA;
}
