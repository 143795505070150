import React, { useState, useCallback, useMemo, useEffect } from "react";
import Cookies from "js-cookie";
import { doc, onSnapshot } from "firebase/firestore";
import db from "../firebase";

export const UserContext = React.createContext({});

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let unsubscribe;
        const userId = Cookies.get()?.id;

        if (userId) {
            unsubscribe = onSnapshot(doc(db, "users", userId), (doc) => {
                const userData = doc.data();
                if (userData) {
                    setUser(prev => ({
                        ...prev,
                        ...userData,
                        setprogress: false
                    }));
                }
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    const updateUser = useCallback((updates) => {
        setUser(prev => ({
            ...prev,
            ...updates
        }));
    }, []);

    const value = useMemo(() => ({
        user,
        setUser: updateUser,
        isLoading
    }), [user, updateUser, isLoading]);

    if (isLoading) {
        return null;
    }

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};
