import { React, useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import {
	collection,
	doc,
	addDoc,
	onSnapshot,
	updateDoc,
} from "firebase/firestore";
import db from "../firebase";
import Cookies from "js-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "./User";
import getBrowserFingerprint from "get-browser-fingerprint";
import { ReactComponent as LagoonLogo } from "../assets/logo.svg";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Firestore } from "firebase/firestore";
import PopUpMessage from "./PopUpMessage";
import useTexts from "../hooks/useTexts";
import { DataContext } from "./DataContext";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


export default function SignUp1() {

	const { DATA, setDATA } = useContext(DataContext);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();
	const { user, setUser } = useContext(UserContext);
	const [users, setUsers] = useState([]);
	const [userId, setUserId] = useState(false);
	const [fingerprint, setFingerprint] = useState();
	const [popUp, setPopUp] = useState(false);
	const [popup, setPopup] = useState("");
	const [input, setInput] = useState("");
	const [accountCode, setAccountCode] = useState("");
	const [Logo, setLogo] = useState("");

	useEffect(() => {
		if (users.length === 0) {
			onSnapshot(collection(db, "users"), (snapshot) =>
				setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
			);
		} else {
			users.forEach((user) => {
				if (user.fingerprint === fingerprint) {
					Cookies.set("id", user.id);
					setUser(user);
					// return user;
				}
			});
		}
	}, [users]);

	/*
	useEffect(() => {
		if (!fingerprint) {
			const fpPromise = FingerprintJS.load({
				apiKey: "XjuHS179kM6xN4lfdR95",
			});
			fpPromise
				.then((fp) => fp.get())
				.then((result) => {
					setFingeprint(result.visitorId);
				});
		} else {
			console.log(fingerprint);
		}
	}, [fingerprint]);
	*/

	const generateFingerprint = async (ipAddress) => {
		try {
			const fp = await FingerprintJS.load();
			const { visitorId } = await fp.get();

			// Prepend IP address (without periods) to the fingerprint if available
			const finalFingerprint = ipAddress
				? ipAddress.replace(/\./g, "") + visitorId
				: visitorId;
			console.log("Generated fingerprint:", finalFingerprint);

			return finalFingerprint;
		} catch (error) {
			console.log("Error generating fingerprint:", error);
			return null;
		}
	};

	const updateFingerprintState = (finalFingerprint) => {
		if (finalFingerprint !== null) {
			console.log("Setting fingerprint in state:", finalFingerprint);
			setFingerprint(finalFingerprint);
			Cookies.set("fingerprint", finalFingerprint); // Store fingerprint in cookies
		} else {
			console.log("Setting empty fingerprint in state.");
			setFingerprint("");
			Cookies.remove("fingerprint"); // Remove the fingerprint cookie
		}
	};

	useEffect(() => {
		if (!fingerprint) {
			console.log("Generating fingerprint...");

			const fetchIpAddress = () => {
				console.log("Fetching IP address...");
				return fetch("https://api.ipify.org?format=json")
					.then((response) => response.json())
					.then((data) => {
						const ipAddress = data.ip;
						console.log("Fetched IP address:", ipAddress);
						return ipAddress;
					})
					.catch((error) => {
						console.log("Error fetching IP address:", error);
						return null; // Return null in case of any error
					});
			};

			fetchIpAddress()
				.then((ipAddress) => generateFingerprint(ipAddress))
				.then((finalFingerprint) => updateFingerprintState(finalFingerprint))
				.catch((error) => {
					console.log("Error in fingerprint generation:", error);
					updateFingerprintState(null); // Handle error case
				});
		} else {
			console.log("Fingerprint already set:", fingerprint);
		}
	}, [fingerprint]);

	useEffect(() => {

		if (Cookies.get("projectId")) {

			let projectId = Cookies.get("projectId");
			onSnapshot(doc(db, "projects", projectId), (doc) => {

				let project = doc.data();

				if (project) {

					if (typeof project["logo"] !== "undefined" && project["logo"] != "")
						setLogo(project["logo"]);
					root?.style.setProperty("--background", project['COLORS'][1]['color']);
					root?.style.setProperty("--buttons_background", project['COLORS'][9]['color']);
					root?.style.setProperty("--buttons_color", project['COLORS'][10]['color']);
				}
			});
		}
	}, []);

	const closeModal = async () => {

		//console.log(input+' === '+accountCode);

		if (input === accountCode) {

			setDATA({auth: true});
			Cookies.set("id", user.id);
			const user_ref = doc(db, "users", user.id);
			await updateDoc(user_ref, {
				fingerprint: fingerprint,
			});

			window.location.reload(true)

			navigate(url);

		} else {
			document.querySelector("#error").innerHTML =
				"Looks like your code is incorrect - please, try again!";
		}
	};

	const closeModal1 = () => {
		navigate("/");
	};


	//=======================================
	var texts = {
		sms: {
			text: 'Welcome back to Lagoon! Your login code is #code#',
		}
	};
	var templates = [
		{
			code: '#code#',
			value: accountCode,
		},
	];
	texts = useTexts('confirming_phone', templates, texts);
	//console.log(texts);
	//=======================================

	useEffect(() => {
		//console.log(accountCode);
		if (accountCode.length !== 0) {
			//console.log(accountCode);
			// const user_ref = doc(db, "users", user.id);
			// updateDoc(user_ref, {
			// 	accountCode: accountCode,
			// });
			addDoc(collection(db, "sms"), {
				to: user.phone,
				body: 'Welcome back! Your login code is ' + accountCode
				// body: texts['sms']['text'],
			});
		}
	}, [accountCode]);

	const checkUser = async (data) => {

		data.phone = '+' + data.phone;

		setPopUp(true);
		setPopup("Sorry we can't find your account, please sign in!");

		users.forEach(async (user) => {
			if (user.phone === data.phone) {
				setUser(user);
				setAccountCode(
					Date.now()
						.toString()
						.substring(Date.now().toString().length - 4) +
					Math.floor(Math.random() * 100)
				);
				setPopup("Enter your activation code from the text message");
			}
		});
	};
	// return Cookies.get().id ? (
	// 	<Navigate to="/" />
	// ) : (
	// Check if the user is already authenticated
	const isAuthenticated = Cookies.get("id") || userId;

	const [url, setUrl] = useState('/');
	// useEffect(() => {
	// 	let url = "/";
	// 	if (DATA && DATA['projectId'] && typeof (DATA['projectId']) !== 'undefined') {
	// 		setUrl('/' + DATA['projectId'] + '/');
	// 		let temp = DATA;
	// 		temp['auth'] = true;
	// 		setDATA(temp);
	// 	}
	// }, [DATA, DATA['projectId']]);

	return isAuthenticated ? (
		<div>
			<Navigate to={url} />
		</div>
	) : (
		<div className="signUp">
			{Logo ? (
				<img className="lagoon-logo" src={Logo} />
			) : (
				<LagoonLogo className="lagoon-logo" />
			)}
			<h2 id="signin-header">Welcome back!</h2>
			<form id="signin-form">
				{/* <label htmlFor="firstName">First Name</label> */}
				{/*
				<input
					{...register("phone", { required: "Your phone number is required" })}
					placeholder="Your Phone number"
				/>
				*/}
				<Controller
					control={control}
					{...register("phone", {
						required: "Please enter your phone",
						pattern: {
							value:
								/^[0-9]{11}$/,
							message: "Invalid phone",
						},
						}
					)}
					render={({ field }) => (
						<PhoneInput
							{...field}
							country={'us'}
						/>
					)}
				/>
				<p>{errors.phone?.message}</p>
				<button
					onClick={handleSubmit((data) => {
						checkUser(data);
						// reset();
					})}
					type="submit"
				>
					Retrieve account
				</button>
				<button className="no-button" onClick={() => navigate("/signup")}>
					Back to sign in page
				</button>
			</form>
			<PopUpMessage
				trigger={popUp}
				closeModal={user && user.id ? closeModal : closeModal1}
			>
				{
					<div>
						{user.id ? <h2>Please enter the code from text message</h2> : ""}
						<p>{popup}</p>
						{user.id ? (
							<input
								id="activation-input"
								name="validation"
								placeholder="Input your code here"
								onChange={(e) => setInput(e.target.value)}
							/>
						) : (
							""
						)}
						{user.id ? <p id="error"></p> : ""}
					</div>
				}
			</PopUpMessage>
		</div>
	);
}
