import react, { useContext, useEffect, useCallback, useRef, useState } from "react";
import Activities from "./Activities";
import { useSearchParams, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Packets from "./Packets";
import Profile from "./Profile";
import { UserContext } from "./User";
import { doc, onSnapshot, addDoc, updateDoc, collection } from "@firebase/firestore";
import db from "../firebase";
import Cookies from "js-cookie";
import ConfettiComp from "./ConfettiComp";
import PacketConfetti from "./PacketConfetti";
import PopUpMessage from "./PopUpMessage";
import InAppNotification from "./InAppNotification";
import useScore from "../hooks/useScore";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DataContext } from "./DataContext";
import { useParams, useLocation } from 'react-router-dom';
import { DefaultData, ProcessData } from "./Data";
import Projects from "./Projects";
import useAnalytics from "../hooks/useAnalytics";

gsap.registerPlugin(ScrollTrigger);

export default function DashboardProject(props) {
    console.log('dashboard');

    const userId = Cookies.get()?.id;
    const navigate = useNavigate();
    const location = useLocation();
    const { code } = useParams();
    const [searchParams] = useSearchParams();

    // Context
    const { user, setUser } = useContext(UserContext);
    const { DATA, setDATA } = useContext(DataContext);

    // Refs
    const userDataRef = useRef(user);
    const isMounted = useRef(true);
    const smsSentRef = useRef(false);
    const timeoutRefs = useRef([]);

    // State
    const [projectId, setProjectId] = useState(false);
    const [search, setSearch] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [notification, setNotification] = useState(false);
    const [fire, setFire] = useState(false);
    const [packetFire, setPacketFire] = useState(false);
    const [popUpMessage, setPopUpMessage] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [notificationButtonText, setNotificationButtonText] = useState(null);
    const [notificationButtonUrl, setNotificationButtonUrl] = useState(null);
    const [pointsModal, setPointsModal] = useState(false);
    const [moduleComplete, setModuleComplete] = useState(false);
    const [nextModuleUnlockLink, setNextModuleUnlockLink] = useState(null);
    const [giftCardUnlocked, setGiftCardUnlocked] = useState(false);

    // Get URL parameters
    let unlockParam = searchParams.get("unlock");
    if (!unlockParam && Cookies.get()?.unlock) {
        unlockParam = Cookies.get().unlock;
    }
    let confirmParam = searchParams.get("confirm");

    // Hooks
    const [updateScore] = useScore(10, userId);
    const [updateScoreNew] = useScore(5, userId);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            isMounted.current = false;
            timeoutRefs.current.forEach(timeout => clearTimeout(timeout));
        };
    }, []);

    // Handle new project redirect
    useEffect(() => {
        if (!DATA.newProject) return;

        const cookiesToRemove = [
            "id", "lastLogin", "didShowProgressNotificationModuleOne",
            "showProgressNotificationModuleOne", "profileComplete",
            "giftCardEarnedShow"
        ];

        cookiesToRemove.forEach(cookie => Cookies.remove(cookie));

        if (isMounted.current) {
            setDATA({});
            navigate('/signup');
        }
    }, [DATA.newProject]);

    // User data subscription
    useEffect(() => {
        if (!userId) return;

        const userRef = doc(db, "users", userId);
        const unsubscribe = onSnapshot(userRef, (doc) => {
            if (doc.exists() && isMounted.current) {
                const userData = doc.data();
                userDataRef.current = userData;
                setUser(prevUser => ({...prevUser, ...userData}));
            }
        });

        return () => unsubscribe();
    }, []);

    // Event handlers
    const closeModal = () => {
        if (props.closeModal) props.closeModal(false);
    };

    const closeNotification = () => {
        if (props.closeNotification) props.closeNotification(false);
    };

    const closePage = () => {
        navigate("/");
    };

    function onModalChange(newState) {
        if (isMounted.current) setPopUp(newState);
    }

    function onNotificationChange(newState) {
        if (isMounted.current) setNotification(newState);
    }

    const completeProfile = () => {
        if (!user?.progress?.[DATA?.ID]?.bio?.link) return;
        navigate(user.progress[DATA.ID].bio.link);
        Cookies.set("didShowProgressNotificationModuleOne", true);
    };

    // Remove the timeline creation from the top level
    const timelineRef = useRef(null);

    // Add useEffect for GSAP timeline
    useEffect(() => {
        // Wait for next frame to ensure DOM elements are ready
        const timeout = setTimeout(() => {
            if (!document.querySelector('.profile')) return;

            try {
                timelineRef.current = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".profile",
                        pin: false,
                        start: "top top",
                        end: "+=1000",
                        scrub: true,
                    },
                });
            } catch (error) {
                console.error('Error setting up GSAP timeline:', error);
            }
        }, 0);

        return () => {
            // Cleanup timeline and ScrollTrigger
            if (timelineRef.current) {
                timelineRef.current.kill();
            }
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            clearTimeout(timeout);
        };
    }, []); // Empty dependency array since we only want this to run once on mount

    // Update user progress
    const updateUserProgress = async (progress) => {
        try {
            if (!userId) return;
            const userRef = doc(db, "users", userId);
            await updateDoc(userRef, { progress });
        } catch (error) {
            console.error('Error updating user progress:', error);
        }
    };

    // Handle user progress updates
    useEffect(() => {
        if (!userDataRef.current?.progress?.[DATA?.ID] || !DATA) return;

        const processUserProgress = async () => {
            try {
                // Handle confirmation parameter
                if (confirmParam === userId) {
                    if (userDataRef.current.progress[DATA.ID].bio) {
                        let packet_index = userDataRef.current.progress[DATA.ID].bio.packet_index;
                        let activity_index = userDataRef.current.progress[DATA.ID].bio.activity_index;
                        
                        if (!userDataRef.current.progress[DATA.ID]['packets'][packet_index]['activities'][activity_index].completed) {
                            userDataRef.current.progress[DATA.ID]['packets'][packet_index]['activities'][activity_index].completed = true;
                            await updateUserProgress(userDataRef.current.progress);
                            await updateScore(DATA['PACKETS'][packet_index]['activities'][activity_index].points, userId);
                        }
                    }
                }

                // Process packets
                Object.values(userDataRef.current.progress[DATA.ID]['packets']).forEach((packet, packet_index) => {
                    // Check packet completion
                    if (packet_index < userDataRef.current.progress[DATA.ID]['packets'].length - 1) {
                        const allActivitiesCompleted = packet.activities?.every(activity => activity.completed);

                        if (allActivitiesCompleted) {
                            userDataRef.current.progress[DATA.ID]['packets'][packet_index].packet_completed = true;
                            const moduleCompletionShownKey = `moduleCompletionShown_${packet_index}`;

                            if (!Cookies.get(moduleCompletionShownKey) && isMounted.current) {
                                const timeout = setTimeout(() => {
                                    if (!isMounted.current) return;
                                    
                                    let completionMessage = `You have completed the Module: ${packet.name}. Tap below to unlock the next module.`;
                                    setModuleComplete(true);
                                    setNextModuleUnlockLink('/?unlock=packet_' + (packet_index + 1));
                                    setPopUpMessage(completionMessage);
                                    setPopUp(true);
                                    setPacketFire(true);
                                    Cookies.set(moduleCompletionShownKey, true);
                                }, 1500);

                                timeoutRefs.current.push(timeout);
                            }
                        }
                    }

                    // Handle unlock parameter
                    if (packet.packet === unlockParam) {
                        const moduleNotificationShownKey = `moduleNotificationShown_${packet_index}`;

                        if (packet_index != 0 && !userDataRef.current.progress[DATA.ID]['packets'][packet_index].packet_activated) {
                            userDataRef.current.progress[DATA.ID]['packets'][0].packet_activated = true;
                            userDataRef.current.progress[DATA.ID]['packets'][0].badge.is_unlocked = true;
                        }

                        const updatedDATA = { ...DATA, unlock: packet_index };
                        if (isMounted.current) {
                            setDATA(updatedDATA);
                        }

                        userDataRef.current.progress[userDataRef.current.project_id].activeBadge = packet_index;
                        userDataRef.current.progress[DATA.ID]['packets'][packet_index].packet_activated = true;
                        userDataRef.current.progress[DATA.ID]['packets'][packet_index].badge.is_unlocked = true;

                        updateUserProgress(userDataRef.current.progress);

                        if (!Cookies.get(moduleNotificationShownKey) && isMounted.current) {
                            if (window.umami) {
                                window.umami.track("Unlock module: " + unlockParam);
                            }

                            let unlockMessage = "You just unlocked Module " + DATA['PACKETS'][packet_index]['name'] + "!";
                            if (userDataRef.current.score === 0) {
                                updateScoreNew(5, userId);
                                unlockMessage += " Here's 5 points to get you started!";
                            }

                            setPopUpMessage(unlockMessage);
                            setPopUp(true);
                            setFire(true);
                            Cookies.set(moduleNotificationShownKey, true);
                        }

                        Cookies.remove('unlock');
                    }
                });
            } catch (error) {
                console.error('Error processing user progress:', error);
            }
        };

        processUserProgress();
    }, [userDataRef.current, DATA]);

    return (
        <div className="dashboard">
            {DATA?.status === 'draft' ? (
                <div className="disabled-project">
                    This project is disabled
                </div>
            ) : (
                <>
                    <ConfettiComp fire={fire} />
                    <PacketConfetti fire={packetFire} />
                    <PopUpMessage onModalChange={onModalChange} hideButton={moduleComplete} trigger={popUp}>
                        {
                            <div>
                                <h2>Congratulations!</h2>
                                <p>{popUpMessage}</p>
                                {pointsModal && (
                                    <button
                                        style={{ width: "200px", marginBottom: "-35px" }}
                                        className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
                                        onClick={completeProfile}
                                    >
                                        Complete my profile
                                    </button>
                                )}
                                {moduleComplete && (
                                    <button
                                        style={{ width: "200px", marginBottom: "-35px" }}
                                        className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
                                        onClick={() => navigate(nextModuleUnlockLink)}
                                    >
                                        Unlock next module!
                                    </button>
                                )}
                                {giftCardUnlocked && (
                                    <button
                                        style={{ width: "200px", marginBottom: "-35px" }}
                                        className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
                                        onClick={() => navigate("/rewards")}
                                    >
                                        Check my Rewards
                                    </button>
                                )}
                            </div>
                        }
                    </PopUpMessage>
                    <InAppNotification
                        onModalChange={onNotificationChange}
                        trigger={notification}
                        buttonText={notificationButtonText}
                        navigateTo={notificationButtonUrl}
                    >
                        <div>
                            <p>{notificationMessage}</p>
                            {pointsModal && (
                                <button
                                    style={{ width: "200px", marginBottom: "-35px" }}
                                    className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
                                    onClick={completeProfile}
                                >
                                    Complete my profile
                                </button>
                            )}
                        </div>
                    </InAppNotification>

                    <Profile showAllBadges={true} />
                    <Packets />
                    <Footer />
                </>
            )}
        </div>
    );
}
