import React, { useState, useEffect } from "react";
import { DefaultData, ProcessData } from "./Data";

export const DataContext = React.createContext();

export const DataProvider = ({ children }) => {
  const [DATA, setDATA] = useState(DefaultData);

  useEffect(() => {
    if (!DATA.processed) {
      const processedData = ProcessData(DATA);
      setDATA(processedData);
    }
  }, [DATA]);

  return (
    <DataContext.Provider value={{ DATA, setDATA }}>
      {children}
    </DataContext.Provider>
  );
};
