import {
	collection,
	deleteDoc,
	doc,
	onSnapshot,
	updateDoc,
} from "@firebase/firestore";
import { async } from "@firebase/util";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import db from "../firebase";

export default function useCookie(name, defaultValue) {
	const navigate = useNavigate();

	const [users, setUsers] = useState([]);

	const [value, setValue] = useState(() => {
		const cookie = Cookies.get(name);
		if (cookie) return cookie;
		return defaultValue;
	});

	useEffect(() => {
		if (!Cookies.get(name)) {
			Cookies.set(name, defaultValue);
		}
	}, [name, defaultValue]);

	useEffect(
		() =>
			onSnapshot(collection(db, "users"), (snapshot) =>
				setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
			),
		[]
	);

	const updateCookie = useCallback(
		(newValue, options) => {
			Cookies.set(name, newValue, options);
			setValue(newValue);
		},
		[name]
	);

	const deleteCookie = useCallback(() => {
		if (name === "id") {
			// deleteDoc(doc(db, "users", Cookies.get().id));
		}
		Cookies.remove(name);
	
		// Get all cookies
		const allCookies = Cookies.get();
	
		// Iterate over the cookies and remove those that match the pattern
		// Object.keys(allCookies).forEach(cookieName => {
		// 	if (cookieName.startsWith('notificationShown_')) {
		// 		Cookies.remove(cookieName);
		// 	}
		// });

		Object.keys(allCookies).forEach(cookieName => {
			if (cookieName.startsWith('moduleNotificationShown_')) {
				Cookies.remove(cookieName);
			}
		});

		Object.keys(allCookies).forEach(cookieName => {
			if (cookieName.startsWith('moduleCompletionShown_')) {
				Cookies.remove(cookieName);
			}
		});
		
		// Remove other specific cookies
		Cookies.remove("lastLogin");
		Cookies.remove("didShowProgressNotificationModuleOne");
		Cookies.remove("showProgressNotificationModuleOne");
		Cookies.remove("profileComplete");
		Cookies.remove("giftCardEarnedShow");
		Cookies.remove("phoneNumber");
	
		setValue(null);
		navigate("/", { replace: true });
		return false;
	}, [name]);
	

	return [value, updateCookie, deleteCookie];
}
