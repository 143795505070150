import React, { useState, useContext, useEffect } from "react";
import Timer from "./Timer";
import {
	onSnapshot,
	collection,
	getDoc,
	doc,
	updateDoc,
} from "firebase/firestore";
import db from "../firebase";
import "../styles/FoundMatch.css";
import { useNavigate } from "react-router";
import useScore from "../hooks/useScore";
import { UserContext } from "./User";
import Cookies from "js-cookie";
import PopUpMessage from "./PopUpMessage";
import ConfettiComp from "./ConfettiComp";

const FoundMatch = (props) => {
	const [users, setUsers] = useState([]);
	const [input, setInput] = useState(null);
	// const [randomMatch, setRandomMatch] = useState('')
	const scoreIncrement = 7;
	const action = "memory";
	const id = Cookies.get().id;
	const navigate = useNavigate();
	const [updateScore] = useScore(scoreIncrement, id);
	const { user } = useContext(UserContext);
	const [minSecs, setMinSecs] = useState({});
	const [message, setMessage] = useState(false);
	const [popUp, setPopUp] = useState(false);
	const [matchNumber, setMatchNumber] = useState(null);
	const [myNumber, setMyNumber] = useState(null);
	const userArray = props.users;
	const [fire, setFire] = useState(false);

	useEffect(() => {
		// setMinSecs({ minutes: , seconds: time[1] });
		setMinSecs({ minutes: props.time[0], seconds: props.time[1] });
	}, []);

	useEffect(() => {
		const getMatch = async () => {
			const memoryDoc = await getDoc(doc(db, "memory", "gameStart")).then(
				async (memoryDocRef) => {
					let data = await memoryDocRef.data();
					userArray.forEach((dbUser) => {
						if (
							data.matchNum[user.email] === data.matchNum[dbUser.email] &&
							user.email !== dbUser.email
						) {
							setMatchNumber(data.fourNums[dbUser.email]);
							setMyNumber(data.fourNums[user.email]);
						}
					});
					// if (matchNumber === null) {
					// 	setMatchNumber(memoryDocRef.data().fourNums[`${randomId}`]);
					// }
				}
			);
			// console.log("hello i am doc ref in memory", memoryDocRef.data());
			// const fourNumsIds = Object.keys(memoryDocRef.data().fourNums);
			// let randomId = fourNumsIds[fourNumsIds.length * Math.random() | 0];

			// if (user.companyId !== randomId) {
			// 	setRandomMatch(randomId)
			// };

			// console.log('i am random match', randomMatch);
			// add check incase there isn't an even amount of users.

			// console.log(matchNumber);
			// console.log("This is my code " + myNumber);
		};
		getMatch();
		console.log(matchNumber);
	}, [matchNumber]);

	const update = async () => {
		const user_ref = await doc(db, "users", Cookies.get().id);
		await updateDoc(user_ref, {
			"progress.packet_2.activities.memory.completed": true,
		});
	};

	const handleSubmit = (e) => {
		console.log(typeof parseInt(input));
		console.log(typeof matchNumber);
		// console.log(input ===[0]);
		e.preventDefault();
		if (parseInt(input) === matchNumber) {
			// have some sort of sucess message when you found a match. Make it so a user has a button to click to get rid of success message.
			updateScore();
			update();
			setPopUp(true);
			setMessage(true);
			setFire(true);
			// navigate("/dashboard");
		} else {
			// error message if match is incorrect.
			setPopUp(true);
			setMessage(false);
		}
		// setInput(null);
	};
	const closeModal = () => {
		props.closeModal(false);
	};

	const closePage = () => {
		navigate("/");
	};

	return (
		<div className="modalBg text-center font-extrabold text-3xl">
			<div className="modalContainer">
				<div className="titleCloseBtn">
					<button onClick={closeModal}> X </button>
				</div>
				<div className="title">
					{/*need to find out how to make timer from memorygame available here*/}
					<Timer time={minSecs} />
					<ConfettiComp fire={fire} />
				</div>
				<div className="flex-col body">
					<label
						className="mb-4 block text-md font-medium text-gray-700"
						htmlFor="match"
					>
						Input Match Code
					</label>
					<input
						name="match"
						className="border focus:outline-none focus:border-blue-500 shadow mt-1 rounded"
						onChange={(e) => setInput(e.target.value)}
						type="number"
					/>
					<br />
				</div>
				<div className="footer">
					<button className="foundMatch-submit" onClick={handleSubmit}>
						Submit
					</button>
				</div>
				<PopUpMessage
					trigger={popUp}
					closeModal={message === true ? closePage : closeModal}
				>
					{message === true ? (
						<h2>You found your match, congrats!</h2>
					) : (
						<h2>This is not your match!</h2>
					)}
				</PopUpMessage>
				<h5>Your match code is - {myNumber}</h5>
			</div>
		</div>
	);
};

export default FoundMatch;
