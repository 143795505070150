import { useContext, useState, useEffect } from "react";
import { UserContext } from "./User";
import PopUpMessage from "./PopUpMessage";
import { NavLink, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player/vimeo";
import Chevron from "../assets/chevron.svg";
import ConfettiComp from "./ConfettiComp";
import { doc, updateDoc } from "firebase/firestore";
import db from "../firebase";
import Cookies from "js-cookie";
import { DataContext } from "./DataContext";

export default function Wallet() {

	const { DATA, setDATA } = useContext(DataContext);

	const [badgeQuiz, setBadgeQuiz] = useState(false);
	const [badgeVideo, setBadgeVideo] = useState(false);
	const [badgeDownload, setBadgeDownload] = useState(false);
	const [companyName, setCompanyName] = useState("");
	const [badgeCompany, setBadgeCompany] = useState(false);
	const [popup, setPopup] = useState(false);
	const [popupAdd, setPopupAdd] = useState("");
	const [popupEmoji, setPopupEmoji] = useState("");
	const [popupHeading, setPopupHeading] = useState("");
	const [popupContent, setPopupContent] = useState("");
	const [popupText, setPopupText] = useState("");
	const [classChange, setClassChange] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const [fire, setFire] = useState(false);
	const { user, setUser } = useContext(UserContext);
	const [rewards, setRewards] = useState(user.rewards);
	const navigate = useNavigate();

	const getRewardsList = async (user) => {

		if (user && user.rewards) {

			var rewardsEarned = [];
			user.rewards.forEach(async (reward, i) => {
				if (parseInt(reward.price) <= user.score) {
					rewardsEarned.push(reward);
				}
			});

			setRewardsEarned(rewardsEarned);
		}
	}
	const [rewardsEarned, setRewardsEarned] = useState(false);

	useEffect(() => {

		getRewardsList(user);

	}, [user]);

	const classChanger = (reward, e) => {
		setPopupHeading(reward.name);

		const progressRef = reward.progressRef || "gift";

		switch (progressRef) {
			case "mint":
				setPopupContent(user.avatarUrl); // Assuming the avatar URL is stored in `reward.data.assetUrl`
				setPopupText("");
				setPopupAdd(null);
				setPopupHeading('');
				setPopupAdd(
					<button className="download-btn" onClick={() => handleClickDownload(user.avatarUrl)}>
						Download Avatar
					</button>
				);
				break;
			case "download":
				setPopupContent(reward.data.imgPreview);
				setPopupText("");
				setPopupAdd(
					<button className="download-btn" onClick={() => handleClickDownload(reward.data.assetUrl)}>
						View PDF
					</button>
				);
				break;
			case "video":
				setPopupContent(null);
				setPopupText("");
				setPopupAdd(
					<button className="download-btn" onClick={() => window.open(reward.data.url, "_blank")}>
						Watch Video
					</button>
				);
				break;
			case "bio":
				setPopupHeading('Raffle registration');
				// Set up the content for the bio modal
				setPopupContent(null);
				setPopupAdd(null);
				setPopupText("You have successfully registered for the raffle. Make sure to earn enough points to be considered for the main prize.");
				// Additional content for "bio" activity can be added here
				break;
			case "gift":
				// Handle the new reward structure
				setPopupContent(reward.image); // Display the reward image
				setPopupAdd(null);
				setPopupText(reward?.instruction)
				// setPopupText("You've unlocked " + reward.name + ". Show this card at the booth to claim.");
				// Add more logic as needed for the "gift" type
				break;
		}

		setPopup(true);
	};


	const handleClickFromProfile = () => {
		if (document.getElementsByClassName("wallet-activities").length !== 0) {
			navigate("/profile");
		}
	};

	const handleClickDownload = (url) => {
		setFire(true);
		setPopup(false);
		window.open(url, "_blank", "fullscreen=yes");
	};

	const [quiz, setQuiz] = useState(false);

 useEffect(() => {
        let dynamicRewards = [];

        if (!DATA['defaultData'] && user.progress && user.project_id) {
            if (user.progress[user.project_id]) {
                if (user.progress[user.project_id]['packets']) {
                    for (let key in user.progress[user.project_id]['packets']) {
                        let progress_activities = user.progress[user.project_id]['packets'][key]['activities'];

                        for (let key2 in progress_activities) {
                            let progress_activity = progress_activities[key2];
                            let activity = DATA['PACKETS'][key]['activities'][key2];
                            if (activity?.progressRef) {
                                if (progress_activity && activity && progress_activity.completed) {
                                    dynamicRewards.push({
                                        ...activity,
                                        completed: true
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }

        // Ensure we're not setting rewards to an empty array if user data is incomplete
        if (dynamicRewards.length > 0 || !user.rewards) {
            setRewards(dynamicRewards);
        }
    }, [user, DATA]);


	// useEffect(() => {
	// 	if (classChange) {
	// 		setPopup(true);
	// 	} else {
	// 		setPopup(false);
	// 	}
	// }, [classChange]);

	function onModalChange(newState) {
		setPopup(newState);
	}

	return (
		<div className="wallet">
			<div className="wallet-flex" onClick={handleClickFromProfile}>
				{/* {user.avatarRevealed ? (
					<div
						onClick={(e) => classChanger(e, "Avatar")}
						className="wallet-card activity-nft avatar"
						id="new-avatar"
					>
						<img className="wallet-image" src={user.avatarUrl} alt="" />
					</div>
				) : (
					""
				)} */}
				{user.logoRevealed ? (
					<div
						onClick={(e) => classChanger(e, "Mint")}
						className="wallet-card"
						id="mint"
					>
						<img className="wallet-image" src={`${user.logoUrl}`} alt="" />
					</div>
				) : (
					""
				)}
				{badgeQuiz ? (
					<div
						onClick={(e) => classChanger(e, "Quiz")}
						className="wallet-card activity-nft"
						id="quiz"
					>
						<p>Quiz</p>
						<div className="emoji">🎯</div>
					</div>
				) : (
					""
				)}
				{badgeCompany ? (
					<div
						onClick={(e) => classChanger(e, "Company")}
						className="wallet-card activity-nft"
						id="bio"
					>
						<p>{companyName}</p>
						<div className="emoji">🏢</div>
					</div>
				) : (
					""
				)}
				{badgeVideo ? (
					<div
						onClick={(e) => classChanger(e, "Video")}
						className="wallet-card activity-nft"
						id="mint"
					>
						<p>Video</p>
						<div className="emoji">🎥</div>
					</div>
				) : (
					""
				)}
				{badgeDownload ? (
					<div
						onClick={(e) => classChanger(e, "PDF")}
						className="wallet-card activity-nft"
						id="mint"
					>
						<p>PDF</p>
						<div className="emoji">📄</div>
					</div>
				) : (
					""
				)}
				{user.drinkRedeemed ? (
					<div
						onClick={(e) => classChanger(e, "Drink voucher")}
						className="wallet-card"
						id="voucher"
					>
						<p>Drink code: </p>
						<p className={``}>{voucher}</p>
						{/* <button className={``} onClick={voucherUsed}>
									Use it
								</button> */}
						<div className="emoji">🥂</div>
					</div>
				) : (
					""
				)}

				{
					rewardsEarned && rewardsEarned.length > 0 ? (
						rewardsEarned.map((reward, index) => (
							<div
								key={index}
								style={{ backgroundImage: `url(${reward.image})`, backgroundRepeat: 'no-repeat' }}
								className="wallet-card activity-nft earned-reward"
								onClick={(e) => classChanger(reward, e)}
							>
								<p className="reward-name">{reward.name}</p>
							</div>
						))
					) : ""
				}

				{
					rewards?.map((reward, i) => {
						if (reward.completed) {
							let rewardClass = "wallet-card activity-nft";
							let rewardIcon = null;
							let rewardLabel = reward.name;
							let rewardStyle = {};

							switch (reward.progressRef) {
								case "mint":
									rewardClass += " avatar";
									// rewardIcon = "🎯";
									rewardStyle = { backgroundImage: `url(${user.avatarUrl})` };
									rewardLabel = '';
									break;
								case "quiz":
									rewardClass += " quiz";
									rewardIcon = "🎯";
									break;
								case "download":
									rewardClass += " download";
									rewardIcon = "📄";
									break;
								case "video":
									rewardClass += " video";
									rewardIcon = "🎥";
									break;
								case "bio":
									rewardClass += " bio";
									rewardLabel = 'Raffle ticket';
									rewardIcon = "🎟️";
									break;
								// Add more cases as needed for other types of rewards
							}

							return (
								<div
									key={i}
									className={rewardClass}
									style={rewardStyle}
									onClick={(e) => classChanger(reward, e)}
								>
									<p>{rewardLabel}</p>
									{rewardIcon && <div className="emoji">{rewardIcon}</div>}
								</div>
							);
						}
					})
				}


				<NavLink className="wallet-button" to="/profile">

					<img className="wallet-svg" src={Chevron}></img>

				</NavLink>
			</div>

			<ConfettiComp fire={fire} />
			<PopUpMessage trigger={popup} onModalChange={onModalChange}>
				{
					<>
						<div
							className={`wallet-card ${!showDetails ? "" : "hide-details"}`}
						>
							{popupContent ? (
								<img className="wallet-image" src={popupContent} alt="" />
							) : (
								<div className="emoji">{popupEmoji}</div>
							)}
						</div>
						<div className={`flip-side ${!showDetails ? "" : "hide-details"}`}>
							<h2>{popupHeading}</h2>
							<div className="popup-text">{popupText}</div>
							{popupAdd}
						</div>
						{/* <button className="show-details" onClick={handleShowDetails}>
								{buttonForDetails}
							</button> */}
					</>
				}
			</PopUpMessage>
		</div>
	);
}
