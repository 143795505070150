import { React, useEffect, useState, useContext, useRef, forwardRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { collection, addDoc, onSnapshot, doc } from "firebase/firestore";
import db from "../firebase";
import Cookies from "js-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "./User";
import { DataContext } from "./DataContext";
import { ReactComponent as LagoonLogo } from "../assets/logo.svg";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// Create a forwarded ref version of PhoneInput
const PhoneInputWithRef = forwardRef((props, ref) => (
	<PhoneInput {...props} ref={ref} />
));

PhoneInputWithRef.displayName = 'PhoneInputWithRef';

export default function SignIn() {
	const { DATA, setDATA } = useContext(DataContext);

	const {
		register,
		
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const { user, setUser } = useContext(UserContext);
	const [users, setUsers] = useState([]);
	const [phone, setPhone] = useState("");
	const navigate = useNavigate();
	const [userId, setUserId] = useState(false);
	const [fingerprint, setFingerprint] = useState("");
	const [popupVisible, setPopupVisible] = useState(false);
	const [popupMessage, setPopupMessage] = useState("");
	const isMounted = useRef(true);

	// Cleanup on unmount
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	// Refactor users subscription
	useEffect(() => {
		let unsubscribe = () => {};
		
		if (users.length === 0) {
			unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
				if (!isMounted.current) return;
				setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
			});
		}

		return () => unsubscribe();
	}, [users]);

	const generateFingerprint = async (ipAddress) => {
		try {
			const fp = await FingerprintJS.load();
			const { visitorId } = await fp.get();

			// Prepend IP address (without periods) to the fingerprint if available
			const finalFingerprint = ipAddress
				? ipAddress.replace(/\./g, "") + visitorId
				: visitorId;
			console.log("Generated fingerprint:", finalFingerprint);

			return finalFingerprint;
		} catch (error) {
			console.log("Error generating fingerprint:", error);
			return null;
		}
	};

	const updateFingerprintState = (finalFingerprint) => {
		if (finalFingerprint !== null) {
			console.log("Setting fingerprint in state:", finalFingerprint);
			setFingerprint(finalFingerprint);
			Cookies.set("fingerprint", finalFingerprint); // Store fingerprint in cookies
		} else {
			console.log("Setting empty fingerprint in state.");
			setFingerprint("");
			Cookies.remove("fingerprint"); // Remove the fingerprint cookie
		}
	};

	// Refactor fingerprint generation
	useEffect(() => {
		if (!fingerprint && isMounted.current) {
			console.log("Generating fingerprint...");

			const fetchIpAddress = async () => {
				try {
					console.log("Fetching IP address...");
					const response = await fetch("https://api.ipify.org?format=json");
					const data = await response.json();
					console.log("Fetched IP address:", data.ip);
					return data.ip;
				} catch (error) {
					console.log("Error fetching IP address:", error);
					return null;
				}
			};

			const initializeFingerprint = async () => {
				try {
					const ipAddress = await fetchIpAddress();
					const finalFingerprint = await generateFingerprint(ipAddress);
					if (isMounted.current) {
						updateFingerprintState(finalFingerprint);
					}
				} catch (error) {
					console.log("Error in fingerprint generation:", error);
					if (isMounted.current) {
						updateFingerprintState(null);
					}
				}
			};

			initializeFingerprint();
		}
	}, [fingerprint]);

	const signUpUser = async (data) => {

		setDATA({auth: true});

		data.phone = '+' + data.phone;

		console.log(data);
		const currentProjectId = Cookies.get().projectId;
		console.log('Signing up user for project:', currentProjectId);

		const existingUser = users.find((u) => 
			u.phone === data.phone && 
			u.project_id && u.project_id === currentProjectId
		);

		if (existingUser) {
			// User with the same phone number already exists
			setPopupMessage(
				"Looks like somebody with the same phone number is already registered. Try logging in instead."
			);
			setPopupVisible(true);
			return; // Don't proceed with registration
		}

		let isUser = false;

		if (users.length !== 0) {
			users.forEach((user) => {
				if (user.phone === data.phone && user.project_id === pId) {
					Cookies.set("id", user.id);
					Cookies.set("lastLogin", Date.now());
					Cookies.set("phoneNumber", user.phone);
					setUser(user);
					isUser = true;
				}
			});
		}

		if (!isUser) {

			function generateUniqueRandomNumber(users, avatarsTotal) {
				const existingNumbers = new Set(users.map(user => user.randomGiftNum));
				let attempts = 0;
			
				while (attempts < 3) {
					let randomNumber = Math.floor(Math.random() * (parseInt(avatarsTotal) + 1));
					if (!existingNumbers.has(randomNumber)) {
						return randomNumber;
					}
					attempts++;
				}
			
				// Fallback: return a random number even if it's not unique
				return Math.floor(Math.random() * (parseInt(avatarsTotal) + 1));
			}
			
			let randomGiftNum = generateUniqueRandomNumber(users, avatarsTotal);


			// OLD LOGIC FOR RANDOM GIFT NUMBER
			// let randomGiftNum =
			// 	users.length +
			// 	Math.floor(Math.random() * 25) +
			// 	Math.floor(Math.random() * 99) +
			// 	Math.floor(Math.random() * 30);
			// randomGiftNum =
			// 	randomGiftNum +
			// 	Math.floor(Math.random() * 5) +
			// 	Math.pow(Math.floor(Math.random() * 5), 2) -
			// 	Math.floor(Math.random() * 10);
			// if (randomGiftNum >= 300) {
			// 	randomGiftNum = users.length;
			// 	if (randomGiftNum >= 300) {
			// 		randomGiftNum -= Math.floor(Math.random() * 99);
			// 	}
			// }

			const fields = {
				firstName: data.firstName,
				lastName: "",
				phone: data.phone,
				profileComplete: false,
				score: 0,
				fingerprint: fingerprint,
				progress: {},
				project_id: currentProjectId,
				rewards: [],
				setprogress: false,
				logoUrl:
					"https://firebasestorage.googleapis.com/v0/b/lagoon-118b4.appspot.com/o/logos%2F" +
					randomGiftNum +
					".png?alt=media",
				avatarUrl:
					avatarsBucket + avatarsPrefix +
					randomGiftNum +
					"." + avatarsExt,
				avatarRevealed: "",
				logoRevealed: false,
				drinkRedeemed: false,
				lastLogin: Date.now(),
				activationCode:
					Date.now()
						.toString()
						.substring(Date.now().toString().length - 4) +
					Math.floor(Math.random() * 100),
				randomGiftNum: randomGiftNum,
				drinkCode:
					data.firstName +
					Date.now()
						.toString()
						.substring(Date.now().toString().length - 4),
			};
			const newUser = await addDoc(collection(db, "users"), fields);
			Cookies.set("id", newUser.id); // Set the 'id' cookie for the new user

			// Remove older cookies
			const allCookies = Cookies.get();
			Object.keys(allCookies).forEach(cookieName => {
				if (cookieName.startsWith('notificationShown_')) {
					Cookies.remove(cookieName);
				}
			});
			Cookies.set("lastLogin", Date.now());
			Cookies.set("phoneNumber", data.phone);
			Cookies.set("projectId", currentProjectId);
			setUser(fields);
		}
	};

	// Refactor user cookie check
	useEffect(() => {
		if (!isMounted.current) return;

		if (Cookies.get("id") && Cookies.get("phoneNumber")) {
			const userIdCookie = Cookies.get("id");
			const phoneNumberCookie = Cookies.get("phoneNumber");
			const matchingUser = users.find(
				(user) => user.id === userIdCookie && user.phone === phoneNumberCookie
			);
			if (matchingUser) {
				setUser(matchingUser);
			}
		}
	}, [users]);

	const [Logo, setLogo] = useState("");

	const [registrationConsent, setRegistrationConsent] = useState("I consent to receive promotional communications (which may include phone, email, and social). I understand I may proactively opt out of communications at any time.");
	const [registrationAgree, setRegistrationAgree] = useState("By clicking submit, I agree to the use of my personal information in accordance with Privacy Policy.");

	const [avatarsBucket, setAvatarsBucket] = useState('https://r2.lagoon.live/');
	const [avatarsTotal, setAvatarsTotal] = useState(3000);
	const [avatarsPrefix, setAvatarsPrefix] = useState('fortinet-');
	const [avatarsExt, setAvatarsExt] = useState('jpg');
	const [pId, setPId] = useState('');

	// Refactor project subscription
	useEffect(() => {
		let unsubscribe = () => {};

		if (Cookies.get("projectId")) {
			const projectId = Cookies.get("projectId");
			setPId(projectId);

			unsubscribe = onSnapshot(doc(db, "projects", projectId), (doc) => {
				if (!isMounted.current) return;

				const project = doc.data();
				if (project) {
					// Update UI elements
					if (isMounted.current) {
						document.querySelector('.loader')?.classList.add('loaded');

						// Update registration texts
						if (project["ui_texts"]) {
							const { registration_consent, registration_agree } = project["ui_texts"];
							if (registration_consent?.value?.text) {
								setRegistrationConsent(registration_consent.value.text);
							}
							if (registration_agree?.value?.text) {
								setRegistrationAgree(registration_agree.value.text);
							}
						}

						// Update avatar settings
						if (project["avatars"]) {
							const { avatars_total, avatars_bucket, avatars_prefix, avatars_ext } = project["avatars"];
							if (avatars_total?.value?.value) setAvatarsTotal(avatars_total.value.value);
							if (avatars_bucket?.value?.value) setAvatarsBucket(avatars_bucket.value.value);
							if (avatars_prefix?.value?.value) setAvatarsPrefix(avatars_prefix.value.value);
							if (avatars_ext?.value?.value) setAvatarsExt(avatars_ext.value.value);
						}

						// Update logo and colors
						if (typeof project["logo"] !== "undefined" && project["logo"] !== "") {
							setLogo(project["logo"]);
						}
						const root = document.documentElement;
						if (project['COLORS']) {
							root?.style.setProperty("--background", project['COLORS'][1]['color']);
							root?.style.setProperty("--buttons_background", project['COLORS'][9]['color']);
							root?.style.setProperty("--buttons_color", project['COLORS'][10]['color']);
						}
					}
				}
			});
		}

		return () => unsubscribe();
	}, []);

	const [url, setUrl] = useState('/');
	// Refactor URL effect
	useEffect(() => {
		if (!isMounted.current) return;

		if (DATA?.projectId && typeof DATA.projectId !== 'undefined') {
			setUrl('/' + DATA.projectId + '/');
			setDATA({...DATA, auth: true});
		}
	}, [DATA, DATA.projectId]);

	// Check if the user is already authenticated
	const isAuthenticated = Cookies.get("id") || userId;

	return isAuthenticated ? (
		<div>
			<Navigate to={url} />
		</div>
	) : (
		<div className="signUp">

			{Logo ? (
				<img className="lagoon-logo" src={Logo} />
			) : (
				<LagoonLogo className="lagoon-logo" />
			)}

			<h2 id="signin-header">Join our world</h2>
			<form id="signin-form">
				<input
					{...register("firstName", { required: "Your name is required" })}
					placeholder="Your Name"
				/>
				<p>{errors.firstName?.message}</p>

				{/*
				<input
					{...register("phone", {
						required: "Please enter your phone",
					})}
					placeholder="phone"
				/>
				*/}

				<Controller
					name="phone"
					control={control}
					rules={{
						required: "Please enter your phone",
						pattern: {
							value: /^[0-9]{11}$/,
							message: "Invalid phone"
						}
					}}
					render={({ field: { onChange, value, ref } }) => (
						<PhoneInputWithRef
							country={'us'}
							value={value}
							onChange={onChange}
							ref={ref}
							inputProps={{
								required: true,
								autoFocus: true
							}}
						/>
					)}
				/>
				<p>{errors.phone?.message}</p>

				<label className="registration_consent">
					<input
						type="checkbox"
						{...register("registration_consent", {
							required: "Please consent to receive promotional communications",
						})}
					/>
					{registrationConsent}
				</label>
				<p>{errors.registration_consent?.message}</p>

				<button
					onClick={handleSubmit((data) => {
						signUpUser(data);
					})}
					type="submit"
				>
					Join
				</button>

				<div className="registration_agree">{registrationAgree}</div>

				<button className="no-button" onClick={() => navigate("/signup1")}>
					I already have an account
				</button>
			</form>

			{/* Popup for duplicate phone number */}
			{popupVisible && (
				<div className="popup">
					<p>{popupMessage}</p>
					<button onClick={() => setPopupVisible(false)}>Close</button>
				</div>
			)}
		</div>
	);
}
