import { useState, useContext } from "react";
import { DataContext } from "../components/DataContext";

function format_text (text, templates){
    for (let key in templates){
	let template = templates[key];
	let reg = new RegExp(template['code'], "g");
	text = text.replace(reg, template['value']);
    }
    return text;
}
function strip_tags (str){
    return str.replace(/<\/?[^>]+(>|$)/g, "");
}

export default function useTexts(name, templates, default_values) {

    let texts = default_values;

    /*
    ['email']['subject']
    ['email']['html']
    ['sms']['text']
    */

    const {DATA, setData} = useContext(DataContext);

    if (DATA['texts'] && DATA['texts'][name]){
	texts = DATA['texts'][name];
	console.log('@2');
	console.log(texts);
    }

    for (let type_key in texts){
	for (let value_key in texts[type_key]){
	    let value = texts[type_key][value_key];
	    if (!texts[type_key][value_key]){
		texts[type_key][value_key] = default_values[type_key][value_key];
	    }
	    if (value_key != 'html')
		texts[type_key][value_key] = strip_tags(texts[type_key][value_key]);
	    texts[type_key][value_key] = format_text (texts[type_key][value_key], templates);
	}
    }

    return texts;
}
