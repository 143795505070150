import db from "../firebase";
import { useContext } from "react";
import { UserContext } from "../components/User";
import { doc, updateDoc, onSnapshot } from "@firebase/firestore";

export default function useScore(scoreIncrement, id) {
	const { user, setUser } = useContext(UserContext);
	let score = 0;
	if (user) {
		score = parseInt(user.score);
	}
	// const module_score = user.progress
	// const userScore = user.score;

	// onSnapshot(doc(db, "users", id), (doc) => {
	// 	setUser(doc.data());
	// });
	const updateScore = async () => {
		//console.log(user);
		const _ref_users = await doc(db, "users", id);
		//console.log(_ref_users);
		updateDoc(_ref_users, {
			score: parseInt(score) + parseInt(scoreIncrement),
		});
	};

	return [updateScore];
}
