import twilioConfig from "../twilioConfig";

const client = require("twilio")(
	twilioConfig.accountSid,
	twilioConfig.authToken
);

// const accountSid = process.env.TWILIO_ACCOUNT_SID;
// const authToken = process.env.TWILIO_AUTH_TOKEN;
// const client = require("twilio")(accountSid, authToken);
// console.log(client);

export default function SendMessage(req, res) {
	console.log("FFFFFFFFFF");
	async (req, res) => {
		console.log(req, res);
		res.statusCode = 200;
		res.setHeader("Content-Type", "application/json");
		client.messages
			.create({
				from: twilioConfig.phoneNumber,
				to: req.body.to,
				body: req.body.body,
			})
			.then(() => {
				res.send(JSON.stringify({ success: true }));
				console.log("SSSSSSS");
			})
			.catch((err) => {
				console.log(err);
				res.send(JSON.stringify({ success: false }));
			});
	};

	return (
		<>
			<form method="post">
				<input type="button" value="Submit" onClick={() => a(req, res)} />
			</form>
		</>
	);
}
