import React from "react";
import { useNavigate } from "react-router-dom";

const InAppNotification = ({ trigger, onModalChange, children, buttonText = 'Ok', navigateTo }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (navigateTo) {
            navigate(navigateTo);
        } else {
            if (onModalChange) {
                onModalChange(false);
            }
        }
    };

    const handleClose = () => {
        if (onModalChange) {
            onModalChange(false);
        }
    };

    return trigger ? (
        <div className="visible popup notification">
            <div className="module-closer" onClick={handleClose}>
                {/* SVG close icon here */}
            </div>
            <div className="popup-inner rounded">
                {children}
                <br />
                <button
                    className="bg-blue-400 rounded px-2 py-1 hover:bg-blue-500 text-center text-white font-medium"
                    onClick={handleClick}
                >
                    {buttonText}
                </button>
            </div>
        </div>
    ) : null;
};

export default InAppNotification;
